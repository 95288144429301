import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Input from "../../components/utils/Input";
import { authRegister, login } from "../../services/auth";

const Registration = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e);
  };
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  useEffect(() => {
    if (auth.isAuth) {
      return navigate("/activate");
    }
  }, [auth.isAuth]);
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "all", reValidateMode: "onChange" });
  const onSubmit = useCallback((data) => {
    if (data.email) {
      let successDomain = [
        "5ballov.ru",
        "aeterna.ru",
        "aim.com",
        "algxmail.com",
        "ameritech.net",
        "aol.com",
        "att.net",
        "autorambler.ru",
        "bigmir.net",
        "bk.ru",
        "charter.net",
        "clear.net.nz",
        "cox.net",
        "email.it",
        "fastmail.com.au",
        "fastmail.fm",
        "flash.net",
        "fmgirl.com",
        "fotoplenka.ru",
        "free.fr",
        "fromru.com",
        "front.ru",
        "games.com",
        "gmail.com",
        "gmx.de",
        "gmx.net",
        "googlemail.com",
        "hotbox.ru",
        "hotmail.co.nz",
        "hotmail.com",
        "hotmail.ru",
        "hotpop.com",
        "imapmail.org",
        "inbox.ru",
        "interia.pl",
        "km.ru",
        "krovatka.su",
        "land.ru",
        "lenta.ru",
        "libero.it",
        "list.ru",
        "live.com",
        "love.com",
        "mail.ru",
        "mail15.com",
        "mail333.com",
        "megabox.ru",
        "memori.ru",
        "meta.ua",
        "msn.com",
        "myrambler.ru",
        "myrealbox.com",
        "naui.net",
        "newmail.ru",
        "nfmail.com",
        "nightmail.ru",
        "nl.rogers.com",
        "nm.ru",
        "nvbell.net",
        "nxt.ru",
        "o2.pl",
        "olympus.ru",
        "operamail.com",
        "orange.net",
        "pacbell.net",
        "photofile.ru",
        "pisem.net",
        "pochta.com",
        "pochta.ru",
        "pochtamt.ru",
        "pop3.ru",
        "post.ru",
        "pplmail.com",
        "premoweb.com",
        "prodigy.net",
        "qip.ru",
        "rambler.ru",
        "rbcmail.ru",
        "rikt.ru",
        "ro.ru",
        "rocketmail.com",
        "rogers.com",
        "sbcglobal.net",
        "seznam.cz",
        "sibnet.ru",
        "sky.com",
        "sky.ru",
        "skynet.be",
        "smtp.ru",
        "snet.net",
        "softhome.net",
        "startfree.com",
        "su29.ru",
        "swbell.net",
        "talktalk.net",
        "telenet.be",
        "telus.net",
        "tlen.pl",
        "ua.fm",
        "ukr.net",
        "unliminet.de",
        "verizon.net",
        "wans.net",
        "web.de",
        "wow.com",
        "wp.pl",
        "xtra.co.nz",
        "ya.ru",
        "yahoo.ca",
        "yahoo.co.id",
        "yahoo.co.in",
        "yahoo.co.kr",
        "yahoo.co.nz",
        "yahoo.co.th",
        "yahoo.co.uk",
        "yahoo.com",
        "yahoo.com.ar",
        "yahoo.com.au",
        "yahoo.com.br",
        "yahoo.com.cn",
        "yahoo.com.hk",
        "yahoo.com.mx",
        "yahoo.com.my",
        "yahoo.com.ph",
        "yahoo.com.sg",
        "yahoo.com.tw",
        "yahoo.com.vn",
        "yahoo.de",
        "yahoo.dk",
        "yahoo.es",
        "yahoo.fr",
        "yahoo.ie",
        "yahoo.it",
        "yahoo.no",
        "yahoo.pl",
        "yahoo.se",
        "yahoomail.com",
        "yandex.ru",
        "ymail.com",
        "zebra.lt",
        "ziza.ru",
      ];
      let domain = data.email.split("@")[1];
      if (!domain || !successDomain.includes(domain)) {
        NotificationManager.error(
          "Пожалуйста, введите адрес электронной почты известного нам почтового клиента"
        );
        return false;
      }
    }
    authRegister(data)
      .then(() => {
        NotificationManager.success("Завершите регистрацию, подтвердив почту");
        dispatch(login({ login: data.email, password: data.password }));
      })
      .catch(
        (err) =>
          err &&
          NotificationManager.error(
            err?.response?.data?.error ?? "Неизвестная ошибка при регистрации"
          )
      );
  }, []);

  return (
    <main>
      <Meta title="Регистрация" />
      <Container>
        <section className="pt-4 pt-sm-5 mb-6">
          <Row className="justify-content-center">
            <Col xs={12} lg={10} xl={8} xxl={7}>
              <h1 className="text-center">Регистрация</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="g-3 g-sm-4 align-items-center">
                  <Col md={4}>
                    <h6 className="mb-0">Ник:</h6>
                  </Col>
                  <Col md={8}>
                    <Input
                      autoComplete="new-password"
                      type="text"
                      // label="Имя пользователя"
                      placeholder="Введите имя пользователя"
                      name="nickname"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Введите имя пользователя",
                        minLength: {
                          value: 3,
                          message: "Минимально 3 символа",
                        },
                        maxLength: {
                          value: 20,
                          message: "Максимально 20 символов",
                        },
                        pattern: {
                          value: /^[a-z0-9_]+$/,
                          message: "Никнейм должен включать только строчные латинские буквы и цифры",
                        },
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <h6 className="mb-0">Email:</h6>
                  </Col>
                  <Col md={8}>
                    <Input
                      autoComplete="new-password"
                      type="email"
                      // label="Email"
                      placeholder="Введите Email"
                      name="email"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Введите Email",
                        minLength: {
                          value: 3,
                          message: "Минимально 3 символа",
                        },
                        maxLength: {
                          value: 250,
                          message: "Максимально 250 символов",
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Неверный формат Email",
                        },
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <h6 className="mb-0">Пароль:</h6>
                  </Col>
                  <Col md={8}>
                    <Input
                      autoComplete="new-password"
                      type="password"
                      // label="Пароль"
                      placeholder="Придумайте пароль"
                      name="password"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Введите пароль",
                        minLength: {
                          value: 6,
                          message: "Минимальное кол-во символов 6",
                        },
                        maxLength: {
                          value: 30,
                          message: "Максимальное кол-во символов 30",
                        },
                        pattern: {
                          value: /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z]{6,}/g,
                          message:
                            "Пароль должен содержать не менее 6 символов латинского алфавита или цифр",
                        },
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <h6 className="mb-0">Подтверждение пароля:</h6>
                  </Col>
                  <Col md={8}>
                    <Input
                      autoComplete="new-password"
                      type="password"
                      // label="Подтверждение пароля"
                      placeholder="Повторите пароль"
                      name="passwordConfirm"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Введите повторный пароль",
                        minLength: {
                          value: 6,
                          message: "Минимальное кол-во символов 6",
                        },
                        maxLength: {
                          value: 30,
                          message: "Максимальное кол-во символов 30",
                        },
                        pattern: {
                          value: /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z]{6,}/g,
                          message:
                            "Пароль должен содержать не менее 6 символов латинского алфавита и цифры",
                        },
                      }}
                    />
                  </Col>
                  <Col md={4}>
                  </Col>
                  <Col md={8}>
                    <div className="mt-4 d-flex align-items-center">
                      <input
                        type="checkbox"
                        onChange={(e) => handleCheckboxChange(e.target.checked)}
                      />
                      <p className="text-center ms-3">
                        Я принимаю условия
                        <Link to="/rules" target="_blank"> <u>Правила Сайта</u>, </Link>
                        <br /> <Link to="/agency" target="_blank"><u>Агентский договор</u>, </Link>
                        <Link to="/license-agreement" target="_blank"><u>Лицензионное соглашение</u></Link>
                      </p>
                    </div>
                  </Col>
                </Row>


                <button
                  type="submit"
                  disabled={!isValid || !isChecked}
                  className="btn-5 fs-13 mt-4 mx-auto"
                >
                  Зарегистрироваться
                </button>
                <h6 className="mt-4 mt-sm-5 text-center">
                  У Вас уже аккаунт?
                  <Link to="/login" className="color-1 ms-1">Войти</Link>
                </h6>
              </form>
            </Col>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default Registration;
