import React from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from 'react-router-dom';
import { IoEllipsisHorizontal } from 'react-icons/io5'
import { BiEdit, BiShow } from 'react-icons/bi'
import { customPrice } from '../helpers/all';

const OfferLine2 = ({ category, uid, status, server, desc, count, price }) => {
  const id = uid;
  return (
    <tr className="table-line"  >
      <td>{category?.title}</td>
      <td> <span title={server?.title}>{server?.title.split(' ')[0]}{server?.title.split(' ')[1] && "..."}</span></td>
      <td className='clamp-on2'>{desc}</td>
      <td>{count}</td>
      <td>{customPrice(price)}</td>
      <td>
        <Dropdown align="end">
          <Dropdown.Toggle variant="simple">
            <IoEllipsisHorizontal />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link to={`/lot/${id}`}>
              <Dropdown.Item as="button">
                <BiShow />
                Открыть
              </Dropdown.Item>
            </Link>
            <Link to={`edit/${id}`}>
              <Dropdown.Item as="button">
                <BiEdit />
                Редактировать
              </Dropdown.Item>
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr >
  );
};

export default OfferLine2;