import React from "react";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AccountMenu from "../pages/account/AccountMenu";
import { useSelector } from "react-redux";
import { customPrice } from "../helpers/all";

const AccountLayout = ({ isMobile }) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <main className="account">
      <Container>
        {isMobile ? (
          <Outlet />
        ) : (
          <section className="pt-4 mb-6">
            <div className="d-flex justify-content-between align-items-center mb-5">
              <h1 className="mb-0">Личный кабинет</h1>
              <div>
                Баланс:{" "}
                <span className="fw-5">{customPrice(user?.cash ?? 0)}</span>
              </div>
            </div>
            <Row>
              <Col md={4} lg={3}>
                <AccountMenu />
              </Col>
              <Col md={8} lg={9}>
                <Outlet />
              </Col>
            </Row>
          </section>
        )}
      </Container>
    </main>
  );
};

export default AccountLayout;
