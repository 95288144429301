import React, { useMemo } from 'react';
import StarRating from './utils/StarRating';
import { Link } from 'react-router-dom';
import { getImageURL, treeAll } from '../helpers/all';
import ReplacementImg from "../assets/imgs/imageReplacement.jpg";
import { useSelector } from 'react-redux';

const OfferLine = React.memo(({ uid, desc, servers, count, server, user, total, notDesc, options, data }) => {
  const image = useMemo(() => getImageURL({ path: user?.media }), [user?.media]);
  const id = uid;

  const renderOptions = useMemo(() => {

    return options.map((item, i) => {
      const option = item.option;
      const key = option.id || i;
      // if (option?.parent && opt) {
      //   let spanOpt = treeAll(option, opt);
      //   if (spanOpt[0].data?.desc) {
      //     return spanOpt.map((item, index) => (
      //       <React.Fragment key={item.id}>
      //         <span className={index !== spanOpt.length - 1 && 'me-2'}>{item.title}</span>
      //         {options?.length !== index + 1 && options[index + 1]?.option?.data?.desc && index === spanOpt.length - 1 && (
      //           <span className="me-2">,</span>
      //         )}
      //       </React.Fragment>
      //     ));
      //   }
      // }


      if (!option || !option.data || !option.data.desc) return null; // Проверка наличия данных

      const title = option.title || '';
      const value = item.value || '';

      return (
        <React.Fragment key={key}>
          <span className={value && 'me-2'}>{title}</span>
          {value && <span>{value}</span>}
          {options?.length != (i + 1) && < span className="me-2">,</span>}
        </React.Fragment >
      );
    });
  }, [options, desc]);


  return (
    <li className={server ? "lot-line" : "lot-line-no"}>
      {server && (
        <Link to={`/lot/${id}`}>
          <div className="lot-line-server">{server.title}</div>
        </Link>
      )}
      <Link to={`/lot/${id}`}>
        <div className={server ? "lot-line-descr" : "lot-line-no-descr"}>
          {!notDesc ? (
            <>
              {desc && <span className='desc clamp-on'>{desc}<br /></span>}
              {options && options?.length > 0 && <div className='desc clamp-on'>{renderOptions}</div>}
            </>
          ) : (
            <>
              <span>{count}</span>
              <span className='gray ms-1'>{data?.typeCount ? data?.typeCount : "шт."}</span>
            </>
          )}
        </div>
      </Link>
      <div className={server ? "lot-line-user" : "lot-line-no-user"}>
        <Link
          to={`/profile/${user?.id}`} >
          {
            (image) ?
              <div className='img'><img src={image} alt={user?.nickname} /></div>
              : <div className='img'><img src={ReplacementImg} alt={user?.nickname} /></div>
          }
        </Link>
        <div>
          <Link
            to={`/profile/${user?.id}`} >
            {(user?.firstName) && <h5>{user?.firstName}</h5>}
            <p className='achromat-3 mb-xl-1 mb-0'>{user?.nickname}</p>
          </Link>
          <StarRating value={user?.rating} />
        </div>
      </div>
      <Link className={server ? "lot-line-price" : "lot-line-no-price"} to={`/lot/${id}`}>
        {Math.round(parseFloat(total) * 10000) / 10000}&nbsp;руб.
      </Link>

    </li >
  );
});

export default OfferLine;