import React from 'react';
import { Link } from 'react-router-dom';

const ServerSwitcher = React.memo(({ serversArr, active, data }) => {
  const activeServerId = active ? active : serversArr[0].id;

  const generateLinkTo = (obj, id) => {
    const baseParams = `region=${obj.id}`;
    const additionalParams = data.param ? `&param=${data.param}` : '';
    return `/${data.uid ?? data.categoryId ?? id}/?${baseParams}${additionalParams}`;
  };

  return (
    <ul className="servers">
      {serversArr.filter(obj => obj.status).map((obj) => (
        <li key={obj.id}>
          <Link
            to={generateLinkTo(obj)}
            type='button'
            id={obj.id}
            className={activeServerId === obj.id ? 'btn-4 active' : 'btn-4'}
          >
            {obj.title}
          </Link>
        </li>
      ))}
    </ul>
  );
});

export default ServerSwitcher;
