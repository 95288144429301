import React from 'react';

const GameMiniCard = ({ id, title, onGameChange, currentGame }) => {

  return (
    <button
      type="button"
      className={`game-btn ${currentGame == id ? 'active' : ''}`}
      onClick={() => onGameChange(id)}
    >
      <h6 className="mb-0">{title}</h6>
    </button>
  );
};

export default GameMiniCard;