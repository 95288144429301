import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { getImageURL } from '../helpers/all';
import { FiArrowRight } from "react-icons/fi";
import ReplacementImg from "../assets/imgs/imageReplacement.jpg";

const BlogCard2 = ({ id, media, title, createdAt, content }) => {
  const date = createdAt ? moment(createdAt).format("DD MMMM YYYY") : null
  const image = getImageURL({ path: media, type: "news" })
  function createMarkup() {
    return { __html: content };
  }
  return (
    <article className='news-midi'>
      <div className='text'>
        <h3>{title}</h3>
        <p dangerouslySetInnerHTML={createMarkup()}></p>
        <time>{date}</time>
        <Link to={`/blog/${id}`} className='stretched-link'>
          <span>Читать далее</span>
          <FiArrowRight />
        </Link>
      </div>
      <img src={image ?? ReplacementImg} alt={title} />
    </article>
  );
};

export default BlogCard2;