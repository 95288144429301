import React from 'react';
import Container from 'react-bootstrap/Container';
import bgImg from '../assets/imgs/bg/gradient.jpg';
import { Row } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <main>
      <Container>
        <div className='rules'>
          <div className='top'>
            <h1>Политика конфиденциальности</h1>
          </div>
          <div className='content'>
            <p>Настоящая политика конфиденциальности (далее – Политика) действует в отношении персональных данных, которые могут быть получены ИП Разуваева Елена Сергеевна ИНН: 323203455433 ОГРН: 324320000033480 (далее – SpeedyTrade) в процессе использования вами:</p>
            <p>веб-сайта https://speedytrade.ru, принадлежащего SpeedyTrade, и его поддоменов (далее вместе — Сайт),</p>
            <p>любых программ, продуктов и/или сервисов, предоставляемых на Сайте (далее вместе - Сервисы), </p>
            <p>а также в ходе исполнения SpeedyTrade любых соглашений и договоров, заключённых с вами в связи с использованием вами Сервисов на Сайте.</p>
            <p>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
            <p>1.1. Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);</p>
            <p>1.2. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
            <p>Действия и операции, которые SpeedyTrade осуществляет с персональными данными, зависят от используемого вами Сервиса, и могут быть доведены до вас до начала обработки данных при помощи соответствующих юридических документов (оферт, условий использования и т.д.) или элементов пользовательского интерфейса.</p>
            <p>1.3. SpeedyTrade обрабатывает только те данные и только теми способами, которые перечислены в Политике и которые характеризуют вас как пользователя Сайта и/или Сервисов. При обработке персональных данных SpeedyTrade руководствуется нормами международного законодательства и законодательства Российской Федерации.</p>
            <p>1.4. Ваше обращение к Сайту означает ваше согласие с Политикой и ее условиями обработки персональных данных. Вы также можете дать согласие на обработку ваших персональных данных при заполнении формы регистрации на Сайте и иными способами, предусмотренными Политикой.</p>
            <p>В случае несогласия с Политикой вы должны воздержаться от использования Сайта SpeedyTrade.</p>
            <p>1.5. SpeedyTrade не может проверить и потому доверяет вам в том, что вы:</p>
            <p>обладаете полной дееспособностью;</p>
            <p>предоставили собственные достоверные персональные данные.</p>
            <p>1.6. SpeedyTrade вправе вносить изменения в Политику, в т.ч. в связи с изменениями законодательства о защите персональных данных, без уведомления Пользователя.</p>
            <p>При внесении изменений обновленная версия Политики публикуется на Сайте в разделе https://speedytrade.ru/privacy-policy и вступает в силу с даты публикации.</p>
            <p>SpeedyTrade рекомендует вам периодический просмотр актуальной версии Политики. Использование Сайта после публикации изменений в Политике означает ваше согласие с данными изменениями.</p>
            <p>1.7. В случае существенных изменений в Политике мы сообщим вам об этом. SpeedyTrade может попросить дать ваше согласие на продолжение обработки персональных данных, даже если вы давали подобное согласие ранее.</p>
            <p>1.8. Вы можете обратиться к SpeedyTrade по любым вопросам, связанным с исполнением или изменением Политики, а также обработкой персональных данных:</p>
            <p>по электронной почте: support@speedytrade.ru</p>
            <p>воспользовавшись формой обратной связи https://speedytrade.ru/account/callback (для Авторизированных пользователей);</p>
            <p>При обращении укажите тему «Обработка персональных данных».</p>
            <p>SpeedyTrade ответит на ваше обращение не позднее 10 рабочих дней с момента его получения.</p>
            <p>2. ВАШИ ПРАВА</p>
            <p>2.1. В любой момент, когда у SpeedyTrade имеются ваши персональные данные, вы можете воспользоваться следующими правами:</p>
            <p>Право на доступ к вашей персональной информации.</p>
            <p>Право на исправление – у вас есть право запросить SpeedyTrade об исправлении ваших неточных и/или неполных персональных данных;</p>
            <p>Право на отзыв – вы вправе в любой момент времени отозвать свое согласие на обработку персональных данных;</p>
            <p>Право на забвение – вы вправе запросить удаление персональных данных, которые есть в отношении вас у SpeedyTrade, за исключением случаев, когда SpeedyTrade обязан хранить такие данные по закону.</p>
            <p>Вы можете требовать ограничений на обработку вашей Персональной информации.</p>
            <p>Если вы хотите, чтобы какие-либо ваши персональные данные были удалены, вы можете обратиться к нам с соответствующим запросом.</p>
            <p>Вы можете обратиться к SpeedyTrade по любым вопросам, связанным с исполнением или изменением Политики, а также обработкой персональных данных.</p>
            <p>В случаях, предусмотренных действующим законодательством, вы можете также обладать другими правами, не указанными выше.</p>
            <p>При использовании вами Сервисов передача Персональной информации осуществляется с вашего согласия, свидетельствующего о выражении вами свободной воли и вашего интереса (в частности, на основании договора с вами), и не является обязанностью по закону.</p>
            <p>2.2. Вы также вправе обратиться к SpeedyTrade, если захотите уточнить порядок реализации иных прав, предусмотренных Федеральным законом «О персональных данных».</p>
            <p>3. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
            <p>3.1. SpeedyTrade обрабатывает персональные данные в цифровой форме (автоматизированно), ограничиваясь следующими действиями:</p>
            <p>сбор;</p>
            <p>хранение;</p>
            <p>систематизация;</p>
            <p>накопление;</p>
            <p>предоставление третьим лицам;</p>
            <p>уточнение (изменение, обновление);</p>
            <p>уничтожение персональных данных.</p>
            <p>3.2. Обработка персональных данных производится SpeedyTrade с использованием баз данных, находящихся на территории Российской Федерации.</p>
            <p>3.3. Цели и правовая основа обработки персональных данных:</p>
            <p>обеспечение работы Сайта;</p>
            <p>соблюдение обязательств, установленных законодательством;</p>
            <p>выполнение договорных обязательств перед пользователями на Сайте и в Сервисах;</p>
            <p>для улучшения качества оказываемых услуг;</p>
            <p>для обеспечения законных интересов SpeedyTrade только в тех случаях, если такая обработка не оказывает существенного влияния и не наносит ущерб вашим интересам, правам и свободам.</p>
            <p>3.4. Данные, которые мы собираем:</p>
            <p>- адрес электронной почты;</p>
            <p>- IP-адрес;</p>
            <p>- cookie-файлы;</p>
            <p>- данные вашей учетной записи;</p>
            <p>- данные о местоположении;</p>
            <p>- данные о браузере;</p>
            <p>- User-Agent (данные об устройстве);</p>
            <p>- информация о вашей активности во время использования Сайта и/или Сервисов;</p>
            <p>- номера банковских карт для покупок и/или оплат товаров и услуг;</p>
            <p>- ваше имя.</p>
            <p>3.4. SpeedyTrade осуществляет запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных с использованием баз данных, находящихся на территории Российской Федерации, в течение срока, необходимого для достижения указанной цели или отзыва согласия на их обработку.</p>
            <p>3.5. По истечении срока обработки персональных данных они удаляются или анонимизируются.</p>
            <p>4. КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
            <p>4.1. SpeedyTrade обязуется обеспечивать конфиденциальность ваших персональных данных, т.е. не распространять и не раскрывать третьим лицам ваши персональные данные за исключением следующих случаев:</p>
            <p>в рамках заключения и исполнения с вами различных договоров, в т.ч. условий использования отдельных Сервисов;</p>
            <p>для исполнения положений законодательства по раскрытию персональных данных в определенном обмене (например, по требованию налоговых органов, по запросу суда или в иных случаях);</p>
            <p>с вашего согласия;</p>
            <p>при наличии иных правовых оснований в соответствии с законодательством.</p>
            <p>Обратите внимание, что уровень защиты Персональной информации в некоторых странах может не совпадать с уровнем, установленном в вашей юрисдикции, и, используя наши Сайт и/или Сервисы, вы соглашаетесь на такую передачу.</p>
            <p>4.2. К числу третьих лиц, которым SpeedyTrade может передавать ваши персональные данные, относятся банки и операторы платежных систем при проведении расчетов на Сайте, государственные органы, запрашивающие персональные данные в пределах своей компетенции. Такие лица обрабатывают ваши персональные данные не от имени SpeedyTrade или в его интересах, поскольку имеют собственные цели и правовые основания такой обработки.</p>
            <p>4.3. Вы также соглашаетесь, что SpeedyTrade вправе поручить обработку ваших персональных данных третьим лицам на основании заключаемых с ними договоров. Это означает, что SpeedyTrade может делегировать часть своих функций по обработке персональных данных определенному лицу, которое при этом будет действовать от имени или в интересах SpeedyTrade. Например, в случае необходимости в дополнительных вычислительных мощностях SpeedyTrade может арендовать выделенные серверы у третьего лица в отсутствие собственных серверов.</p>
            <p>4.4. Когда это необходимо для защиты и/или реализации собственных прав и законных интересов, SpeedyTrade может использовать сторонние сервисы и привлекать третьих лиц для проверки информации о вас, передавая им такую информацию. Таким образом SpeedyTrade, например, вправе использовать доступные ему возможности для выявления и устранения финансовых и юридических рисков, бороться с мошенническим или иным недобросовестным поведением пользователей Сервисов и предотвращать угрозы информационной безопасности, которые могут возникать в отношении Сервисов.</p>


            <p>5. ФАЙЛЫ COOKIE</p>
            <p>5.1. Файлы cookie – это небольшие фрагменты данных с определённой (часто технической) информацией, которые Сайт передает вашему браузеру, а ваш браузер хранит их и передает обратно Сайту с каждым вашим запросом к нему.</p>
            <p>5.2. С помощью файлов cookie, SpeedyTrade собирает такую информацию, как время, проведенное на Сайте, области посещения Сайта, веб-сайты со ссылками на наш Сайт и иную дополнительную информацию.</p>
            <p>Обращаем ваше внимание, что файлы SpeedyTrade не используются для сбора личной информации о вас.</p>
            <p>5.3. Структура файла cookie, его содержание и технические параметры определяются Сайтом и могут быть изменены без направления вам предварительного уведомления.</p>
            <p>5.4. SpeedyTrade применяет файлы cookie для:</p>
            <p>запоминания пользователей Сайта и сохранения их персональных предпочтений и настроек;</p>
            <p>предоставления им персонализированных Сервисов;</p>
            <p>таргетирования рекламы;</p>
            <p>определения статистических и исследовательских целей;</p>
            <p>усовершенствования Сайта и Сервисов на основе пользовательских предпочтений.</p>
            <p>5.5. Обращаем Ваше внимание, что оборудование и программное обеспечение, используемые вами для посещения сайтов, как правило обладают функцией запрета операций с файлами cookie для любых или для определенных веб-сайтов, а также удаления ранее полученных файлов cookie.</p>
            <p>В любое время вы можете самостоятельно удалить сохраненные на вашем компьютере файлы cookie, изменить параметры в настройках вашего браузера таким образом, чтобы ваш браузер перестал сохранять все файлы cookie и оповещал вас об их отправке.</p>
            <p>5.6. Отказ или ограничение в использовании файлов cookie не является препятствием для использования Сайта, однако в этом случае некоторые функции Сайта и Сервисы могут быть для вас недоступны или работать с определенными ограничениями. SpeedyTrade оставляет за собой право установить, что предоставление определенного Сервиса возможно лишь при условии, что прием и получение файлов cookie разрешены пользователем.</p>
            <p>6. СТОРОННИЕ ИНСТРУМЕНТЫ ВЕБ-АНАЛИТИКИ</p>
            <p>6.1. SpeedyTrade также использует сторонние инструменты веб-аналитики для того, чтобы собирать информацию об источниках трафика, посещаемости сайта и оценки эффективности наших Служб. Мы используем: инструменты веб-аналитики Яндекс.Метрика и Google Analytics. Типы персональных данных, которые мы собираем таким образом: дата и время осуществления доступа к Сайту и/или Сервису, электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, данные об идентификаторе браузера и программном обеспечении).</p>
            <p>Подробнее о файлах cookie Яндекс.Метрики можно прочитать на странице https://yandex.ru/support/metrica/general/cookie-usage.html. Подробнее о файлах cookie Google Analytics можно прочитать на странице https://policies.google.com/technologies/partner-sites?hl=ru.</p>
            <p>7. БЕЗОПАСНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
            <p>7.1. SpeedyTrade защищает хранимые персональные данные от разглашения, полной или частичной утраты, а также несанкционированного доступа третьих лиц в строгом соответствии с действующим законодательством.</p>
            <p>7.2. SpeedyTrade использует все необходимые технические и организационные меры по обеспечению безопасности и конфиденциальности хранимых персональных данных и постоянно актуализирует их с учетом последних технических разработок.</p>
            <p>7.3. Если происходит утечка персональных данных, SpeedyTrade:</p>
            <p>в течение 24 часов уведомляет об этом Роскомнадзор;</p>
            <p>в течение 72 часов проводит собственное расследование и уведомляет о его результатах Роскомнадзор.</p>
            <p>8. ОГРАНИЧЕНИЕ ПОЛИТИКИ</p>
            <p>8.1. Политика применима только к Сайту SpeedyTrade и не распространяется на какие-либо другие сторонние веб-сайты, в т.ч. в случаях когда:</p>
            <p>сторонний веб-сайт содержит ссылку на Сайт SpeedyTrade;</p>
            <p>Сайт SpeedyTrade содержат ссылку на сторонний веб-сайт.</p>
            <p>8.2. Порядок обработки персональных данных на сторонних веб-сайтах производится в соответствии с собственными правилами конфиденциальности, которые могут отличаться от данной Политики. В связи с этим рекомендуем вам ознакомиться с этими правилами стороннего веб-сайта при переходе на него.</p>
          </div>
        </div>
      </Container>
    </main >
  );
};

export default PrivacyPolicy;