import moment from "moment";
import React from 'react';
import { IoCheckmarkDoneOutline, IoCheckmarkOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { getImageURL } from '../../helpers/all';
import TextWithLinks from "../TextWithLinks";



const Message = ({ my, id, general, time, media, text, name, admin, user, view = false, type, status, dialog }) => {
  const image = getImageURL({ path: user?.media, type: "user" })
  time = time
    ? moment(time).format("DD.MM.YYYY kk:mm")
    : moment().format("DD.MM.YYYY kk:mm");
  return my ? (
    <div className="chat-box-reverse">
      <div className="chat-box-reverse-user">
        {type == "system" ?
          <img src="/imgs/system.png" alt={name} />
          :
          <img src={image} alt={name} />
        }
      </div>
      <div className='chat-box-reverse-messages'>
        <div className='fs-09 d-flex flex-row-reverse align-items-baseline'>
          {type == "system" &&
            <h6 className="name ms-3">Системное сообщение</h6>

          }
          <time className='ms-3'>
            {time}
          </time>
          {view ? (
            <IoCheckmarkDoneOutline className="text-success ms-1" size={15} />
          ) : (
            <IoCheckmarkOutline className="ms-1" size={15} />
          )}
        </div>
        <div className={type == "system" || !status ? "bubble colored" : "bubble"}>
          {status ?
            <>
              {text && type == "system" ?
                <p><TextWithLinks text={text} from={dialog.from} to={dialog.to} /></p>
                :
                <p>{text}</p>
              }
              {media &&
                <Link to={getImageURL({ path: media, size: "", type: "message" })}>
                  <img src={getImageURL({ path: media, size: "", type: "message" })} className="bubble-img" />
                </Link>
              }
            </>
            :
            <p>Сообщение заблокировано администрацией</p>
          }
        </div>
      </div>
    </div>

  ) : (
    admin && type != "system" ? (
      <div className="chat-box">
        <div className="chat-box-user">
          <img src={image} alt={name} />
        </div>
        <div className='chat-box-messages'>
          <div className='fs-09 d-flex align-items-baseline'>
            <h6 className="name">ADMIN</h6>
            <time className='mx-3'>{time}</time>
          </div>
          {/* Класс colored должен добавляться к bubble у админских сообщений, 
          у уведомлений о покупке и у заблокированных сообщений пользователей */}
          <div className="bubble colored">
            {text && <p>{text}</p>}
            {media &&
              <Link to={getImageURL({ path: media, size: "", type: "message" })} >
                <img src={getImageURL({ path: media, size: "", type: "message" })} className="bubble-img" />
              </Link>
            }
          </div>
        </div>
      </div>
    ) : (
      <div className="chat-box">
        <div className="chat-box-user">
          {type == "system" ?
            <img src="/imgs/system.png" alt={name} />
            :
            <Link to={"/profile/" + id}>
              <img src={image} alt={name} />
            </Link>
          }
        </div>
        <div className='chat-box-messages'>
          <div className='fs-09 d-flex align-items-baseline'>
            {general == "general" &&
              <Link to={"/profile/" + id}>
                <h6 className="name">{user.nickname}</h6>
              </Link>
            }
            {type == "system" &&
              <h6 className="name">Системное сообщение</h6>

            }
            <time className='mx-3'>{time}</time>
            {/* <button type='button' className='report'>
              <WarningIcon />
            </button> */}
          </div>
          <div className={type == "system" || !status ? "bubble colored" : "bubble"}>
            {status ?
              <>
                {text && type == "system" ?
                  <p><TextWithLinks text={text} from={dialog?.from} to={dialog?.to} /></p>
                  :
                  <p>{text}</p>
                }
                {media &&
                  <Link to={getImageURL({ path: media, size: "", type: "message" })}>
                    <img src={getImageURL({ path: media, size: "", type: "message" })} className="bubble-img" />
                  </Link>
                }
              </>
              :
              <p>Сообщение заблокировано администрацией</p>
            }
          </div>
        </div>
      </div>
    )
  )
};
export default Message;