import React from 'react';
import { IoCloseCircleOutline, IoArrowUpCircleOutline  } from "react-icons/io5";
import {Link} from 'react-router-dom';
import ReturnIcon from './svg/ReturnIcon';

const SecFavorites = () => {
  return (
    <section className='sec-favorites'>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <h3 className='mb-0'>Избранное</h3>
        <button type='button' className='d-flex fs-14'>
          <IoArrowUpCircleOutline />
        </button>
      </div>
      <ul className='list-unstyled row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-4 g-xxl-5'>
        <li>
          <div className="sec-favorites-item">
            <Link to='/' className='title'>World of Warcraft®: Wrath of the Lich King® Classic</Link>
            <button type="button">
              <IoCloseCircleOutline/>
            </button>
          </div>
        </li>
        <li>
          <div className="sec-favorites-item">
            <Link to='/' className='title'>Critical Strike</Link>
            <button type="button">
              <IoCloseCircleOutline/>
            </button>
          </div>
        </li>
        <li>
          <div className="sec-favorites-item">
            <Link to='/' className='title'>Critical Strike</Link>
            <button type="button">
              <IoCloseCircleOutline/>
            </button>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default SecFavorites;