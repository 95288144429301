import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { getImageURL } from "../helpers/all";

function GameLarge({ data }) {
    const imgFull = getImageURL({ path: data.media, type: "sale", size: "full", });
    const [regId, setRegId] = useState();
    return (
        <figure className="game-large">
            <img src={imgFull} alt={data?.category?.title} />
            <figcaption>
                <Row className='w-100'>
                    <Col md={9} xl={7} xxl={6}>
                        <h2>
                            <Link to={`/${data?.category?.uid ? data?.category?.uid : data?.category?.id}/?${regId ? `region=${regId}&` : (data?.category?.regions && data?.category?.regions.length > 0 ? `region=${[...data?.category?.regions].sort((a, b) => a.priority - b.priority)[0].id}&` : '')}${data?.category?.params && data?.category.params.length > 0 ? `param=${[...data?.category.params].sort((a, b) => a.priority - b.priority)[0].id}` : ''}`}>
                                {data?.category?.title}</Link>
                        </h2>
                        <div className="mb-4">{data?.desc}</div>
                    </Col>
                    <Col md={10}>
                        <div className="d-flex flex-wrap align-items-center">
                            {data?.category?.regions && data?.category?.regions.length > 0 && [...data?.category?.regions].sort((a, b) => a.priority - b.priority)[0].status ? (
                                <div className="mt-3 mb-3 d-flex flex-wrap align-items-center">
                                    {/* <ServerSwitcher serversArr={el.regions} onChange={handleServerChange} /> */}
                                    {
                                        [...data?.category?.regions].sort((a, b) => a.priority - b.priority).map((obj, index) => {
                                            if (obj.status) {
                                                return <button
                                                    type="button"
                                                    key={index}
                                                    id={obj.id}
                                                    className={(regId ? regId == obj.id : [...data?.category?.regions].sort((a, b) => a.priority - b.priority)[0].id == obj.id) ? 'btn-4 me-2 p-3 fs-09 mb-2 text-uppercase active' : 'btn-2 p-3 fs-09 me-2 mb-2 text-uppercase'}
                                                    onClick={() => {
                                                        setRegId(obj.id)
                                                    }}>{obj.title}</button>
                                            }
                                        })}
                                </div>
                            ) : ""}
                        </div>
                    </Col>
                    <Col md={10}>
                        <div className="d-flex flex-wrap align-items-center">
                            {data?.category?.params?.length > 0 &&
                                [...data?.category?.params].sort((a, b) => a.priority - b.priority).map((category) => (
                                    <Link
                                        key={category.id}
                                        to={`/${data?.category?.uid ? data?.category?.uid : data?.category?.id}/?${regId ? `region=${regId}&` : (data?.category?.regions.length > 0 ? `region=${[...data?.category?.regions].sort((a, b) => a.priority - b.priority)[0].id}&` : '')}${category.id ? `param=${category.id}` : ''}`}
                                        className="btn-2 me-2 mb-2"
                                    >
                                        {category.title}
                                    </Link>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
            </figcaption>
        </figure >
    )
}

export default GameLarge