import React, { useEffect, useRef, useState } from 'react';
import { Container, Offcanvas } from 'react-bootstrap';
import { HiArrowNarrowRight, HiOutlineX } from 'react-icons/hi';
import { Link } from 'react-scroll';
import { FreeMode, Mousewheel } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';
import { Swiper, SwiperSlide } from 'swiper/react';
import GameCard from './GameCard';

const CatalogSection = ({ games }) => {
  const [showOffcanvasNav, setShowOffcanvasNav] = useState(false);
  const [sortVisible, setSortVisible] = useState(false);
  const [sortSwiper, setSortSwiper] = useState()
  const [currentSection, setCurrentSection] = useState();
  const updateSlider = (i) => {
    if (sortSwiper) {
      sortSwiper?.slideTo(i)
      setCurrentSection(i);
    }
  }

  const menuRef = useRef(null)
  const offsetT = -160
  const SortNav = <nav className="sort">
    <Swiper
      loop={false}
      spaceBetween={0}
      slidesPerView={'auto'}
      watchSlidesProgress={true}
      modules={[FreeMode, Mousewheel]}
      initialSlide={currentSection}
      freeMode={{
        enabled: true,
        sticky: true,
      }}
      mousewheel={true}
      onSwiper={setSortSwiper}
    >
      {games?.letters && games?.letters?.map((letter, i) => {
        return (
          <SwiperSlide key={letter.id} >
            <Link
              className="btn-3"
              activeClass="btn-3 active"
              to={"letter-" + i}
              spy={true}
              smooth={true}
              offset={offsetT}
              duration={300}
              onSetActive={() => updateSlider(i)}
            >
              {letter}
            </Link>
          </SwiperSlide>
        )
      })}
    </Swiper>
    <hr className="vertical mx-3" />
    <button type="button" className="sort-more" onClick={() => setShowOffcanvasNav(true)}>
      <HiArrowNarrowRight />
    </button>
  </nav>

  useEffect(() => {
    function updateSort() {
      const menuNode = menuRef.current;
      if (menuNode) {
        const rect = menuNode.getBoundingClientRect();
        const offsetElem = rect.top + window.pageYOffset;
        const scrollTop = window.pageYOffset;
        if (scrollTop > offsetElem) {
          setSortVisible(true);
        } else {
          setSortVisible(false);
        }
      }
    }
    window.addEventListener("scroll", updateSort);
    return () => window.removeEventListener("scroll", updateSort);
  }, []);
  return (
    <>
      <section className='sec-catalog mb-6'>
        <Container>
          <h1>Каталог игр</h1>

          <div id="sort" ref={menuRef}>
            {SortNav}
          </div>

          <div className="sec-catalog-box">
            {games?.letters && games?.letters.map((letter, i) => (
              <section key={letter} id={`letter-${i}`} className="pb-6">
                <div className="d-flex align-items-center mb-4 mb-sm-5">
                  <h3>{letter}</h3>
                  <hr className="horizontal flex-1 ms-4" />
                </div>
                <ul className="list-unstyled gy-3 gx-3 gx-xl-5 row row-cols-lg-4 row-cols-md-3 row-cols-2">
                  {games?.data && <GameCard param1={letter} param2={games.data} />}
                </ul>
              </section>
            ))}
          </div>

          <hr className="horizontal mb-5" />
        </Container>
      </section>

      <header className={sortVisible ? "fixed-sort show" : "fixed-sort"}>
        <Container>
          {SortNav}
        </Container>
      </header>

      <Offcanvas show={showOffcanvasNav} placement={'end'} scroll={true} onHide={() => setShowOffcanvasNav(false)} >
        <Offcanvas.Body className={'px-4 px-sm-5'}>
          <div className="d-flex align-itemc-center justify-content-between mb-4">
            <h3>Каталог</h3>
            <button type="button" className="btn-4 px-3 py-2" onClick={() => setShowOffcanvasNav(false)}>
              <HiOutlineX />
            </button>
          </div>

          <ul className='list-unstyled g-3 g-sm-4 row row-cols-4'>
            {games?.letters && games.letters.map((letter, i) => {
              return (
                <li key={letter}>
                  <Link
                    className="btn-4 px-2 w-100"
                    onClick={() => setShowOffcanvasNav(false)}
                    to={"letter-" + i}
                    spy={true}
                    smooth={true}
                    offset={offsetT}
                    duration={500}
                    onSetActive={() => updateSlider(i)}
                  >
                    {letter}
                  </Link>
                </li>
              )
            })}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CatalogSection;