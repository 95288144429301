import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoSpeedy from "../../assets/images/speedytradeLogo.svg";
import Meta from "../../components/Meta";
import Loader from "../../components/utils/Loader";
import ReturnTitle from "../../components/utils/ReturnTitle";
import {
  getDialogs
} from "../../services/message";
import DialogPreview from "./DialogPreview";
import { useDispatch, useSelector } from "react-redux";
import { updateNotification } from "../../store/reducers/notificationSlice";
import InfiniteScroll from "react-infinite-scroller";

const Messages = ({ isMobileXL }) => {
  const [dialogs, setDialogs] = useState({
    loading: true,
    items: [],
  });
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const unreadDate = useSelector((state) => state.notification.messageDate);
  const onLoadDialogsHash = (data) => {
    getDialogs({ page: data, search: search, size: 50 })
      .then((res) => {
        setDialogs((prev) => ({
          ...prev,
          loading: false,
          items: [...prev.items, ...res.dialogs],
          hasMore: res.dialogs.length > 49 ? true : false,
          count: res.countOnline,
        }))
      }
      )
      .catch(() => setDialogs((prev) => ({ ...prev, loading: false })));
  };
  const onLoadDialogs = (data) => {
    getDialogs({ page: data, search: search, size: 50 })
      .then((res) => {
        setDialogs((prev) => ({
          ...prev,
          loading: false,
          items: [...res.dialogs],
          hasMore: res.dialogs.length > 49 ? true : false,
          count: res.countOnline,
        }))
        dispatch(updateNotification({ message: -1 }));
      }
      )
      .catch(() => setDialogs((prev) => ({ ...prev, loading: false })));
  };
  useEffect(() => {
    onLoadDialogs();
  }, [unreadDate]);
  const onKeyPress1 = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setDialogs((prev) => ({
        ...prev,
        items: "",
      }))
      onLoadDialogs();
    }
  };


  if (dialogs.loading) {
    return <Loader full />;
  }

  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={onLoadDialogsHash}
      hasMore={dialogs.hasMore}
      loader={<Loader />}
    >
      <div className="main p-0 overflow-hidden">
        <Meta title="Сообщения" />

        <div className="p-3 p-md-4 d-md-flex justify-content-between align-items-center">
          <ReturnTitle link={"/account"} title={"Сообщения"} className="mb-0" />
          <form action="" className="mt-3 mt-md-0">
            <input
              type="search"
              placeholder="Поиск пользователя"
              className="p-blue"
              onChange={e => setSearch(e.target.value)}
              onKeyPress={(e) => onKeyPress1(e)}
            />
          </form>
        </div>

        <ul className="list-unstyled">

          <li className="messages-list-preview">
            <div className="img general">
              <img src={LogoSpeedy} alt="Общий чат" />
            </div>
            <div className="header">
              <h4 className="color-1 mb-0 mb-md-2">Общий чат</h4>
            </div>
            <div className="message">Количество участников: {dialogs.count}</div>
            <Link to="general" aria-label="link to chat" className="link-to-chat" />
          </li>
          {dialogs?.items?.length > 0 ? (
            dialogs.items.map((dialog, i) => (
              <DialogPreview key={i} {...dialog} />
            ))
          ) : (
            <li className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
              Нет диалогов
            </li>
          )}

        </ul>
      </div>
    </InfiniteScroll >
  );
};

export default Messages;