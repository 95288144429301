import React from 'react';
import { FiPaperclip } from "react-icons/fi";

export default function SimpleInputFile({ setImage, media, className, multiple }) {
  const onChange = (e) => {
    setImage(e.target.files)
  }

  return (
    <label className={'input-file ' + className}>
      {multiple ? (
        <input type="file" multiple onChange={(e) => onChange(e)} />
      ) : (
        <input type="file" onChange={(e) => onChange(e)} />
      )}
      <FiPaperclip />
      <div className="ind">{media && media.length > 0 && media.length}</div>
    </label>
  );
};