import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { getImageURL } from '../../helpers/all';
import { useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';

const DialogPreview = ({ id, messages, to, from, messageCount }) => {
  const message = messages[0]
  const userId = useSelector((state) => state.auth?.user?.id);
  const user =
    userId == to?.id
      ? from
      : to;

  const [elapsedTime, setElapsedTime] = useState(moment(message.createdAt).fromNow());

  useEffect(() => {
    const interval = setInterval(() => {
      const timePassed = moment(message.createdAt).fromNow();
      setElapsedTime(timePassed);
    }, 1000);
    return () => clearInterval(interval); // Очистить интервал перед перемонтированием компонента
  }, [message.createdAt]);
  const imag = getImageURL({ path: user?.media, type: "user" })

  return (
    <li className="messages-list-preview" key={id}>
      <div className="img">
        <img src={imag} alt="user" />
      </div>
      <div className="header">
        <h4 className="color-1 mb-0 mb-md-2">{user?.nickname}</h4>
      </div>
      <div className="message"><div>{message.text ?? "Нет сообщений"}</div></div>
      <div className="date">{elapsedTime}</div>
      {messageCount > 0 && <div className="count"><Badge bg='danger'>{messageCount}</Badge></div>}
      <Link to={`${id}`} aria-label="link to chat" className="link-to-chat" />
    </li>
  );
};

export default DialogPreview;