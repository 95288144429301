import React from 'react';
import { Link } from 'react-router-dom';
import { getImageURL } from '../helpers/all';
import Moment from 'react-moment'
import ReplacementImg from "../assets/imgs/imageReplacement.jpg";

const BlogCard = ({ id, link, media, title, content, createdAt }) => {
  const image = getImageURL({ path: media, type: "news" })

  return (
    // <figure className={(isMobileLG) ? 'blog-list-item-mobile' : 'blog-list-item'}>
    <article className="news">
      <img src={image ?? ReplacementImg} alt={title} />
      <div className='flex-1'>
        <h5><Link className='stretched-link' to={`/blog/${id}`}>{title}</Link></h5>
        <div className='text' dangerouslySetInnerHTML={{__html: content}} />

        <div className="d-flex align-items-center mt-3">
          <span>
              <Moment locale="ru" format="DD MMMM" date={createdAt} />
          </span>
          <span className="px-3 accent">&#8226;</span>
          {/* <span>{readingTimeFrom} мин. чтения</span> */}
        </div>
      </div>
    </article>
  );
};

export default BlogCard;