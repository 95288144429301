import React, { memo, useState } from "react";
import {
  Link
} from "react-router-dom";

import { useLocation } from 'react-router-dom';

const GameCard = memo(({ param1, param2, onSearch, term }) => {

  const filteredGames = param1 ? param2[param1] : term ? param2.sort((a, b) => {
    const titleA = a.title.toUpperCase();
    const titleB = b.title.toUpperCase();

    if (titleA.startsWith(term?.toUpperCase()) && !titleB.startsWith(term?.toUpperCase())) {
      return -1;
    }
    if (!titleA.startsWith(term?.toUpperCase()) && titleB.startsWith(term?.toUpperCase())) {
      return 1;
    }

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  }) : param2;
  const [regId, setRegId] = useState([]);
  return filteredGames.map((el, i) => (
    <li key={el.id}>
      <h5 className="achromat-2 fw-7">
        <Link onClick={onSearch} to={`/${el.uid ? el.uid : el.id}/?${regId[i] ? `region=${regId[i]}&` : (el?.regions?.length > 0 ? `region=${[...el.regions].sort((a, b) => a.priority - b.priority)[0].id}&` : '')}${el?.params?.length > 0 ? `param=${[...el?.params]?.sort((a, b) => a.priority - b.priority)[0].id}` : ''}`}>
          {el.title}
        </Link>
      </h5>

      {el.regions && el.regions.length > 0 && [...el.regions].sort((a, b) => a.priority - b.priority)[0].status ? (
        <div className="mt-3 d-flex flex-wrap align-items-center">
          {
            [...el.regions].sort((a, b) => a.priority - b.priority).map((obj, index) => {
              if (obj.status) {
                return <button
                  type="button"
                  key={index}
                  id={obj.id}
                  className={(regId[i] ? regId[i] == obj.id : [...el.regions].sort((a, b) => a.priority - b.priority)[0].id == obj.id) ? 'btn-4 p-2 fs-08 me-1 mb-2 text-uppercase active' : 'btn-4 p-2 fs-08 me-1 mb-2 text-uppercase'}
                  onClick={() => {
                    setRegId(prevState => ({ ...prevState, [i]: obj.id }));
                  }}>{obj.title}</button>
              }
              return null;
            })}
        </div>
      )
        :
        ""
      }

      <ul className='list-unstyled mt-3 d-flex flex-wrap align-items-center fs-09'>
        {[...el.params].sort((a, b) => a.priority - b.priority).map((param) => (
          <li
            key={param.id}
            className="me-3 mb-2"
          >
            <Link onClick={onSearch} to={`/${el.uid ? el.uid : el.id}/?${regId[i] ? `region=${regId[i]}&` : (el.regions.length > 0 ? `region=${[...el.regions].sort((a, b) => a.priority - b.priority)[0].id}&` : '')}${param.id ? `param=${param.id}` : ''}`}>{param.title}</Link>
          </li>
        ))}
      </ul>
    </li >
  ));
});

export default GameCard;