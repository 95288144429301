import React from 'react';
import { Link } from 'react-router-dom';

const TextWithLinks = ({ text, from, to }) => {
    const words = text.split(' ');
    return words.map((word, index) => (
        <>
            <span key={index}>
                {word.startsWith('"') ? word.endsWith('"') ? (
                    word.slice(1, -1) == from.nickname ?
                        <Link to={`/profile/${from?.id}`}>
                            {word}
                        </Link>
                        :
                        word.slice(1, -1) == to.nickname ?
                            <Link to={`/profile/${to?.id}`}>
                                {word}
                            </Link> : word
                ) :
                    (
                        word.slice(1, -2) == from.nickname ?
                            <Link to={`/profile/${from?.id}`}>
                                {word}
                            </Link>
                            :
                            word.slice(1, -2) == to.nickname ?
                                <Link to={`/profile/${to?.id}`}>
                                    {word}
                                </Link> : word
                    )
                    : word.startsWith('#') ? (
                        <Link to={`/order/${word.slice(1).replace(/[\.,!?;]$/, '').toLowerCase()}`}>
                            {word}
                        </Link>
                    ) : (
                        word
                    )}
            </span>
            <span> </span>
        </>
    ));
};

export default TextWithLinks;
