import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBreadcrumbs from '../components/NavBreadcrumbs';
import BlogCard2 from '../components/BlogCard2';
import { getArticle } from '../services/article';
import NavPagination from '../components/NavPagination';
import Loader from '../components/utils/Loader';
import { getImageURL } from '../helpers/all';
import BlogSection from '../components/BlogSection';
import Meta from '../components/Meta';

const Article = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1)
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const [articles, setArticles] = useState({
    loading: true,
    items: [],
  });
  function createMarkup() {
    return { __html: articles?.document?.content };
  }
  useEffect(() => {
    getArticle({ id: id, page: currentPage, size: 2 })
      .then((res) => {
        setArticles((prev) => ({
          prev,
          loading: false,
          ...res,
        }))
        setCurrentPage(res.documents.pagination.currentPage)
      })
      .catch(() => setArticles((prev) => ({ ...prev, loading: false })));
  }, [currentPage, id]);
  const image = getImageURL({ path: articles?.document?.media, size: "large", type: "news" })
  if (articles.loading) {
    return <Loader full />;
  }

  return (
    <main>
      <Meta title="Новости" />
      <Container>
        <section className='news-page pt-4 pt-md-0 mb-5'>
          <figure className='news-page-top'>
            <img src={image} alt={articles?.document?.title} />
            <figcaption><h1 className='white'>{articles?.document?.title}</h1></figcaption>
          </figure>
          <Row className='gx-4 gx-xl-5'>
            <Col xs={12} lg={7} xxl={8}>
              <div>
                <h3>{articles?.document?.title}</h3>
                <p dangerouslySetInnerHTML={createMarkup()}></p>
              </div>
            </Col>
            <Col xs={12} lg={5} xxl={4} className='mt-5 mt-lg-0'>
              <div className="box">
                <h2>Популярное</h2>
                {articles?.documents?.recommends?.length > 0 ?
                  <BlogSection articles={articles?.documents?.recommends} />
                  :
                  <h6>Ничего нет</h6>
                }
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default Article;