import React, { useState, useCallback, useEffect } from 'react';
import { HiBookmark } from 'react-icons/hi';

const BtnAddFav = (({ favo, onFav, categoryId }) => {
    const toggleFav = useCallback(() => {
        onFav();
    }, [onFav]);

    return (
        <button
            type="button"
            onClick={toggleFav}
            className={favo ? 'add-fav active' : 'add-fav'}
        >
            {favo ? (
                <span className="me-1">Добавлено&nbsp;в&nbsp;избранное</span>
            ) : (
                <span className="me-1">Добавить&nbsp;в&nbsp;избранное</span>
            )}
            <HiBookmark className="fs-13" />
        </button>
    );
});

export default BtnAddFav;
