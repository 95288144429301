import React from 'react';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';

const AppealLine = ({ id, title, status, comment, type, dialogId, messageCount }) => {
  return (
    <tr className={`table-line${messageCount > 0 ? " bold" : ""}`}>
      <td>
        {status != "new" ? (
          <Link to={id} state={{ id, title, comment, dialogId, type }}>{id}</Link>
        ) : (
          <button type='button' onClick={() => NotificationManager.error('Запрос не обработан')} >
            {id}
          </button>
        )}
      </td>
      <td>
        {status != "new" ? (
          <Link to={id} state={{ id, title, comment, dialogId, type }}>{title}</Link>
        ) : (
          <button type='button' onClick={() => NotificationManager.error('Запрос не обработан')} >
            {title}
          </button>
        )}
      </td>
      <td>
        {status != "new" ? (
          <Link to={id} state={{ id, title, comment, dialogId, type }}>{comment}</Link>
        ) : (
          <button type='button' onClick={() => NotificationManager.error('Запрос не обработан')} >
            {comment}
          </button>
        )}
      </td>
      <td>
        {status != "new" ? (
          status == "close" ?
            <Link to={id} state={{ id, title, comment, dialogId, type }}>
              Закрыт
            </Link>
            :
            <Link to={id} state={{ id, title, comment, dialogId, type }}>
              В&nbsp;процессе
            </Link>
        ) : (
          <button type='button' onClick={() => NotificationManager.error('Запрос не обработан')} >
            Новый
          </button>
        )}

      </td>
    </tr>
  );
};

export default AppealLine;