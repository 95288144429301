import React from 'react';
import { NavLink } from 'react-router-dom';
import useIsMobile from '../../hooks/isMobile';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../services/auth';
import {
  BiBookOpen,
  BiConversation,
  BiEnvelope,
  BiHistory,
  BiLike,
  BiLogOut,
  BiNews,
  BiUserCircle,
  BiWallet,
} from 'react-icons/bi';
import { Badge } from 'react-bootstrap';

const AccountMenu = (props) => {
  const isMobileLG = useIsMobile('1109px')
  const dispatch = useDispatch();
  const unreadCount = useSelector((state) => state.notification.notification);
  return (
    <nav className="menu">
      <ul>
        <li>
          <NavLink to="profile">
            <BiUserCircle />
            <span>Профиль</span>
          </NavLink>
        </li>
        {/* {
          (isMobileLG) &&
          <li>
            <NavLink to="favs">
              <div>Избранное</div>
            </NavLink>
          </li>
        } */}
        <li>
          <NavLink to="offers">
            <BiNews />
            <span>Мои объявления</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="purchase-history">
            <BiHistory />
            <span>История покупок</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="sales-history">
            <BiBookOpen />
            <span>История продаж</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="finance">
            <BiWallet />
            <span>Финансы</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="messages">
            <BiEnvelope />
            <span>Сообщения</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="callback">
            <BiConversation />
            <span>Помощь</span>
            {unreadCount && unreadCount > 0 ? <div className="count"><Badge bg='danger'>{parseInt(unreadCount)}</Badge></div> : ""}
          </NavLink>
        </li>
        <li>
          <NavLink to="feedback">
            <BiLike />
            <span>Отзывы</span>
          </NavLink>
        </li>
        <li>
          <button>
            <BiLogOut />
            <span onClick={() => dispatch(logout())} >Выйти</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default AccountMenu;