import React, { useState } from "react";
import StarRating from "./utils/StarRating";
import moment from "moment";
import { customPrice, getImageURL } from "../helpers/all";
import { useSelector } from "react-redux";
import { Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoEllipsisVertical } from "react-icons/io5";
import { VscChromeClose } from "react-icons/vsc";
import InputRating from "./utils/InputRating";
import { useForm, useWatch } from "react-hook-form";

const OfferLine3 = ({
  id,
  uid,
  author,
  user,
  createdAt,
  status,
  price,
  total,
  product,
  onStatus,
  onClick,
}) => {
  const userId = useSelector((state) => state.auth?.user?.id);
  const mass = user.id == userId ? author : user;
  const coly = user.id == userId ? total : price;
  const image = getImageURL({
    path: mass.media,
    type: "user",
  });
  const [showReview, setShowReview] = useState(false);
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      orderId: id,
    }
  });
  const data = useWatch({ control });
  return (
    <>
      <tr className="table-line">
        <td>
          <div className="order-table">
            <span>{moment(createdAt).format("DD.MM.YYYY")}</span>
            <span className="ms-3 gray">{moment(createdAt).format("kk:mm")}</span>
          </div>

        </td>
        <td>{uid.toUpperCase()}</td>
        <td ><Link to={`/order/${uid}`} state={{ order: true }}>
          <div className="order-table">
            <span className="clamp-on">{product.categoryTitle}, {product?.regionTitle ? `${product.regionTitle}, ` : ''}{product.param.title}</span>
            <span className="clamp-on gray">{product?.serverTitle ? `${product.serverTitle}, ` : ''}{product.desc}</span>
          </div>

        </Link>
        </td>
        <td>
          <Link
            className="user"
            to={`/profile/${mass?.id}`} >
            <img src={image} alt="User8name" className="user__avatar" />
            <div>
              <h5 className="text-start">{mass.nickname}</h5>
              <StarRating value={mass.rating} />
            </div>
          </Link>
        </td>
        <td>
          {status == "ok" ? (
            user.id == userId ? (
              <Dropdown drop={'top'}>
                <Dropdown.Toggle
                  as={React.forwardRef(({ children, onClick }, ref) => (
                    <Link
                      ref={ref}
                      className="py-0 w-100 d-flex align-items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        onClick(e);
                      }}
                    >
                      <span className="green">Подтверждено</span>
                      <IoEllipsisVertical className="green" size={20} />
                    </Link>
                  ))}
                />
                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    as="button"
                    className="w-100"
                    onClick={() => setShowReview(true)}
                  >
                    <div>Оставить отзыв</div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div className="green">Подтверждено</div>
            )
          ) : status == "cancel" ?
            <div className='text-danger'>Отменено</div>
            : (
              <Dropdown drop={'top'}>
                <Dropdown.Toggle
                  as={React.forwardRef(({ children, onClick }, ref) => (
                    <Link
                      ref={ref}
                      className="py-0 w-100 d-flex align-items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        onClick(e);
                      }}
                    >
                      <span className="blue">В процессе</span>
                      <IoEllipsisVertical className="blue" size={20} />
                    </Link>
                  ))}
                />
                <Dropdown.Menu align="end">
                  {user.id == userId ?
                    <Dropdown.Item
                      as="button"
                      className="w-100"
                      onClick={() =>
                        onStatus({ id: id, status: "ok", authorId: 0 })
                      }
                    >
                      <div>Подтвердить</div>
                    </Dropdown.Item>
                    :
                    <Dropdown.Item
                      as="button"
                      className="w-100"
                      onClick={() =>
                        onStatus({ id: id, status: "cancel", authorId: 1 })
                      }
                    >
                      <div>Отменить</div>
                    </Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>
            )}
        </td>
        <td>{customPrice(Math.round(parseFloat(coly) * 10000) / 10000)}</td>
      </tr >
      <Modal show={showReview} onHide={() => setShowReview(false)}>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h3 className="color-1">Оставьте отзыв</h3>
            <button
              type="button"
              onClick={() => setShowReview(false)}
              className="btn-4 px-3 py-2"
            >
              <VscChromeClose />
            </button>
          </div>

          <div>
            <div className="mt-4 mb-2">Ваша оценка:</div>
            <InputRating defValue={data.value} className="fs-15" onChange={e => setValue('value', e)} />
            <div className="mt-4 mb-2">Текст отзыва:</div>
            <textarea
              rows={5}
              placeholder="Отзыв"
              {...register("text")}
              maxLength={100}
            />
            <button type="submit" className="btn-5 w-100 mt-4" onClick={() => { onClick(data), setShowReview(false) }}>
              Отправить
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OfferLine3;
