import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import FeedbackLine from "../components/FeedbackLine";
import GameMiniCard from "../components/GameMiniCard";
import Meta from "../components/Meta";
import NavPagination from "../components/NavPagination";
import Loader from "../components/utils/Loader";
import StarRating from "../components/utils/StarRating";
import { declOfNum, getImageURL } from "../helpers/all";
import { getUser } from "../services/user";

const Profile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState({
    data: {},
    loading: true,
  });
  const navigate = useNavigate();
  const [sum, setSum] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [value, setValue] = useState("")
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const [currentGame, setCurrentGame] = useState(null)
  const onGameChange = (game) => {
    setCurrentGame(game);
  };
  useEffect(() => {
    setCurrentPage(1)
  }, [userId, currentGame]);
  useEffect(() => {
    if (currentGame) {
      getUser({ id: userId, page: currentPage, categoryId: currentGame, value: value })
        .then((res) => res && setUser({
          loading: false,
          data: res.user,
          products: res.products,
          reviews: res.reviews,
          categories: res.categories,
        }))
        .catch(() => setUser((e) => ({ ...e, loading: false })));
    }
    if (!currentGame) {
      getUser({ id: userId, page: currentPage, value: value })
        .then((res) => {
          res && setUser({
            loading: false,
            data: res.user,
            products: res.products,
            reviews: res.reviews,
            categories: res.categories,
          });
          if (res.categories && !currentGame) setCurrentGame(res.categories[0].id)
        })
        .catch(() => setUser((e) => ({ ...e, loading: false })));
    }
  }, [userId, currentPage, currentGame, value]);
  if (user?.loading) {
    return <Loader full />;
  }
  if (user && user?.data?.status === 0) {
    return (
      <main className="d-flex justify-content-center flex-column align-items-center">
        <Container>
          <section className="sec-registration d-flex justify-content-center flex-column align-items-center mb-6">
            <img src="/imgs/icons/warning.png" className="mb-4" />
            <h1 className="h2 text-center mb-3">Пользователь заблокирован</h1>
            <p className="text-muted">
              Пользователь заблокирован или удален по причине нарушения правил
              сайта
            </p>
          </section>
        </Container>
      </main>
    );
  }
  return (
    <main>
      <Meta title={user?.data?.nickname ?? "Профиль"} />
      <section className="user-page pt-5 mb-6">
        <Container>
          <Row className="gx-lg-5">
            <Col lg={7}>
              <Row className="mb-4 mb-sm-5">
                <Col sm={5}>
                  <img src={getImageURL({ path: user?.data?.media })} alt="userphoto" className="img" />
                </Col>
                <Col sm={7}>
                  <h4 className="mb-2">
                    <span className="achromat-3 fw-4">{user?.data?.nickname ?? "Никнейм"}</span>
                  </h4>
                  <div className="achromat-3">
                    {user?.data?.online?.status ? (
                      <span className="text-success">Онлайн</span>
                    ) : user?.data?.online?.end ? (
                      "Был(-а) в сети " +
                      moment(user?.data?.online?.end).fromNow()
                    ) : (
                      "Оффлайн"
                    )}
                  </div>
                  <p className="total-invert mt-2">На сайте с {user?.data?.createdAt ? moment(user?.data?.createdAt).format('LL') : ""}</p>
                  <div className="d-flex mt-4 mt-sm-5 text-center">
                    <div>
                      <p className="total-invert mb-3">Рейтинг</p>
                      <StarRating value={user?.data?.rating ?? 0} />
                    </div>
                    <div className="ms-4 ms-xl-5">
                      <div className="num">{user?.data?.orderSale ?? 0}</div>
                      <p className="lh-1 total-invert mt-1">Завершенных сделок</p>
                    </div>
                  </div>

                </Col>
              </Row>

              <h6>Предложения пользователя</h6>
              <ul className="list-unstyled row row-cols-2 row-cols-md-3 row-cols-lg-2 row-cols-xl-3 g-2 g-sm-3 g-xl-4">
                {user?.categories?.length > 0 && user?.categories.map(item => (
                  <li><GameMiniCard {...item} currentGame={currentGame} onGameChange={onGameChange} /></li>
                ))}
              </ul>

              <Table className="mt-4" borderless responsive>
                <thead>
                  <tr>
                    <th>Категория</th>
                    {user?.products?.items && user?.products?.items.length > 0 && user?.products?.items[0]?.server?.title && <th> Сервер</th>}
                    <th>Описание</th>
                    <th>Количество</th>
                    <th>Цена</th>
                  </tr>
                </thead>
                <tbody>
                  {user?.products?.items?.length > 0 && user?.products?.items?.map(item => (
                    <tr className="cursor" onClick={() => navigate(`/lot/${item.uid}`)}>
                      <td>{item?.param?.title}</td>
                      {item?.server?.title && <td> <span title={item?.server?.title}>{item?.server?.title.split(' ')[0]}{item?.server?.title.split(' ')[1] && "..."}</span></td>}
                      <td className='clamp-on1'><span>{item?.desc}</span>
                        {item?.options?.map((item) => (
                          item?.option?.data?.desc &&
                          <>
                            <span className='me-2'>,</span>
                            <span className={item?.value && 'me-2'}>{item?.option?.title}</span>
                            {item?.value && <span>{item?.value}</span>}
                          </>
                        ))}</td>
                      <td>
                        <span>{item.count}</span>
                        <span className='gray ms-1'>шт.</span>
                      </td>
                      <td>{item?.total}&nbsp;руб.</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <NavPagination totalPages={user?.products?.pagination?.totalPages} onPageChange={onPageChange} />
            </Col>
            <Col lg={5} className="mt-4 mt-lg-0">
              <h6 className="fw-6">Всего {user?.data?.review} {declOfNum(user?.data?.review, ['отзыв', 'отзыва', 'отзывов'])}</h6>

              <div className="d-flex align-items-center mb-4">
                <span className="me-4">Показать:</span>
                <select className="flex-1" onChange={(e) => setValue(e.target.value)} name="" defaultValue={'all'}>
                  <option value="">Все отзывы</option>
                  <option value="5">5 звезд</option>
                  <option value="4">4 звезды</option>
                  <option value="3">3 звезды</option>
                  <option value="2">2 звезды</option>
                  <option value="1">1 звезда</option>
                </select>
              </div>

              <ul className="list-unstyled">
                {user?.reviews?.length > 0 && user?.reviews?.map((item, index) => (
                  <li key={index}>
                    <FeedbackLine {...item} />
                  </li>
                ))}
              </ul>

              {/* <h2 className="text-center">Чат с пользователем</h2>
              {!myId ? (
                <div className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
                  Для отправки сообщений войдите в аккаунт!
                </div>
              ) : (
                <div className="p-0 fs-09">
                  <Chat toId={user?.data?.id} />
                </div>
              )} */}
            </Col>
          </Row>
        </Container>
      </section>
    </main >
  );
};

export default Profile;
