import React, { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import StarRating from "../../components/utils/StarRating";
import NavPagination from "../../components/NavPagination";
import FeedbackLine from "../../components/FeedbackLine";
import PurchaseLine from "../../components/PurchaseLine";
import ReturnTitle from "../../components/utils/ReturnTitle";
import { getReview } from "../../services/review";
import { declOfNum } from "../../helpers/all";
import { useSelector } from "react-redux";
import Loader from "../../components/utils/Loader";
import Meta from "../../components/Meta";

const Feedback = () => {
  const user = useSelector((state) => state.auth?.user);
  const [isMyFeedback, setIsMyFeedback] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const [review, setReview] = useState({
    loading: true,
    items: [],
  });
  useEffect(() => {
    setReview((prev) => ({ ...prev, loading: true }));
    getReview({ page: currentPage, authorId: isMyFeedback })
      .then((res) => {
        setReview((prev) => ({
          prev,
          loading: false,
          ...res,
        }));
        setCurrentPage(res.pagination.currentPage);
      })
      .catch(() => setReview((prev) => ({ ...prev, loading: false })));
  }, [currentPage, isMyFeedback]);
  const totalItems = review?.pagination?.totalItems ?? 0;
  const declension = declOfNum(totalItems, ["отзыв", "отзыва", "отзывов"]);
  if (review.loading) {
    return <Loader full />;
  }
  return (
    <div className="main">
      <Meta title="Отзывы" />

      <div className="mb-4 d-md-flex justify-content-between align-items-center">
        <ReturnTitle link={"/account"} title={"Отзывы"} className="mb-0" />
        <div className="d-flex align-items-center mt-3 mt-md-0">
          <span className="me-2">Ваш рейтинг: </span>
          <StarRating value={user.rating} />
          <span className="ms-2 fw-5">{user?.rating != null ? parseFloat(user?.rating).toFixed(1) : "0.0"}</span>
        </div>
      </div>


      <div className="tabs-group">
        <button
          type="button"
          className={isMyFeedback ? "" : "active"}
          onClick={() => setIsMyFeedback(false)}
        >
          Отзывы на меня
        </button>
        <button
          type="button"
          className={isMyFeedback ? "active" : ""}
          onClick={() => setIsMyFeedback(1)}
        >
          Мои отзывы
        </button>
      </div>

      {isMyFeedback ? (
        /* Мои отзывы */
        review?.items?.length > 0 ? (
          <>
            <ul className="list-unstyled">
              {review.items.map((item) => (
                <li>
                  <FeedbackLine {...item} my={true} />
                </li>
              ))}
            </ul>
            <NavPagination
              totalPages={review?.pagination?.totalPages}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <h3>Нет отзывов</h3>
          </div>
        )
      ) : (
        /* Отзывы на меня */
        review.loading ? (
          <div className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
            Загрузка отзывов...
          </div>
        ) : review?.items?.length > 0 ? (
          <>
            <ul className="list-unstyled">
              {review.items.map((item) => (
                <li>
                  <FeedbackLine {...item} my={false} />
                </li>
              ))}
            </ul>
            <NavPagination
              totalPages={review?.pagination?.totalPages}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <h3>Нет отзывов</h3>
          </div>
        )
      )}
    </div>
  );
};

export default Feedback;
