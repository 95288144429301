import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useForm, useWatch } from "react-hook-form";
import { LuCircleDollarSign, LuEye } from "react-icons/lu";
import { PiCaretLeftLight } from "react-icons/pi";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Meta from "../components/Meta";
import ReviewCard from "../components/ReviewCard";
import Chat from "../components/chat/Chat";
import Input from "../components/utils/Input";
import Loader from "../components/utils/Loader";
import Select from "../components/utils/Select";
import StarRating from '../components/utils/StarRating';
import socket from "../config/socket";
import { refreshAuth } from "../services/auth";
import { createMessage, getMessages } from "../services/message";
import { createOrder, getOrder } from "../services/order";
import { getProduct } from "../services/product";
import { createTask } from "../services/task";
import { customPrice, treeAll } from "../helpers/all";

const LotPage = () => {
  const userId = useSelector((state) => state.auth?.user?.id);
  const { lotId } = useParams();
  const { state } = useLocation();
  const [showShare, setShowShare] = useState(false);
  const navigate = useNavigate();
  const [products, setProducts] = useState({
    loading: true,
    items: [],
  });
  const dispatch = useDispatch();
  const [isPaymentPending, setPaymentPending] = useState(false);
  const status = useSelector((state) => state.auth.user.status);
  const [showAdvice, setShowAdvice] = useState(false);
  const handleCloseAdvice = () => setShowAdvice(false);
  const handleShowAdvice = () => setShowAdvice(true);

  const iframeRef = useRef(null);
  const [showBank, setShowBank] = useState(false);
  const handleCloseBank = () => setShowBank(false);
  const handleShowBank = () => setShowBank(true);
  const [url, setUrl] = useState()

  const { control, reset, setValue } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      fromId: userId,
    },
  });

  const data = useWatch({ control });
  const getPage = () => {
    if (state?.order) {
      getOrder({ id: lotId })
        .then((res) => {
          setProducts((prev) => ({
            prev,
            loading: false,
            items: res.product,
            reviews: res.reviews,
            nickname: res?.product?.param?.data?.nickname,
            nicknameUser: res?.data?.nickname,
            author: res.author,
            user: res.user,
            count: res.count,
            createdAt: res.createdAt,
            price: res.price,
            total: res.total,
          }));
          setValue("toId", userId != res.author?.id ? res.author?.id : res.user?.id);
          setValuePay("productId", res.product?.id)
        })
        .catch(() => setProducts((prev) => ({ ...prev, loading: false })));
    }
    else {
      getProduct({ id: lotId })
        .then((res) => {
          setProducts((prev) => ({
            prev,
            loading: false,
            items: res.product,
            reviews: res.reviews,
            nickname: res?.product?.param?.data?.nickname,
          }));
          setValue("toId", res.product.userId);
          setValuePay("productId", res.product.id)
        })
        .catch(() => setProducts((prev) => ({ ...prev, loading: false })));
    }
  };
  useEffect(() => {
    getPage();
  }, [lotId]);

  const [messages, setMessages] = useState({
    loading: true,
    items: [],
  });


  useEffect(() => {
    if (data.toId && userId != products.items?.user?.id && data.toId != userId) {
      getMessages(data)
        .then((res) => {
          setMessages((prev) => ({
            ...prev,
            loading: false,
            items: res.messages.items,
            dialogId: res.dialog.id,
            dialog: res.dialog,
          }));
          setValue("id", res.dialog.id);
        })
        .catch(() => {
          setMessages((prev) => ({ ...prev, loading: false }))
        });
    }
  }, [data.toId]);
  useEffect(() => {
    const handleMessage = (data) => {

      setMessages(prev => {
        if (data.status) {
          return {
            ...prev,
            loading: false,
            items: [data, ...prev.items],
          };
        } else {
          const messageIndex = prev.items.findIndex(item => item.id === data.id);

          if (messageIndex !== -1) {
            const updatedMessages = [...prev.items];
            updatedMessages[messageIndex] = data;

            return {
              ...prev,
              loading: false,
              items: updatedMessages,
            };
          }

          return prev;
        }
      });
    };

    if (data?.id) {
      socket.emit("createRoom", "message/" + data.id);
      socket.on("message", handleMessage);
      socket.on("report", handleMessage);

      return () => {
        socket.off("message", handleMessage);
        socket.off("report", handleMessage);
        socket.emit("removeRoom", "message/" + data.id);
      };
    }
  }, [data?.id]);

  const onNewMessage = useCallback(
    (text) => {

      createMessage({ ...data, text })
        .then((res) => {
          if (!data?.id) {
            getMessages(data)
              .then((res) => {
                setMessages((prev) => ({
                  ...prev,
                  loading: false,
                  items: res.messages.items,
                  dialogId: res.dialog.id,
                  dialog: res.dialog,
                }));
                setValue("id", res.dialog.id);
              })
              .catch(() => setMessages((prev) => ({ ...prev, loading: false })));
          }
        })

    },
    [data]
  );
  const {
    control: controlPay,
    register: registerPay,
    setValue: setValuePay,
    reset: resetPay,
    formState: { errors: errorsPay, isValid: isValidPay },
    handleSubmit: handleSubmitPay,
  } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues: {
      count: 1,
    },
  });
  const pay = useWatch({ control: controlPay });
  useEffect(() => {
    if (url) {
      const iframe = iframeRef.current;
      iframe.src = url; // Установка URL в iframe
      iframe.onload = () => {
        setPaymentPending(false);
      };
    }
  }, [url]);

  const onPay = useCallback(
    (pay) => {
      if (!pay.type || pay.type <= 0) {
        return NotificationManager.error("Выберите способ оплаты");
      }
      if (!pay.count || pay.count < 1) {
        return NotificationManager.error("Укажите количество товара");
      }
      if (products.items.count - pay.count < 0) {
        return NotificationManager.error(
          "У продавца недостаточно кол-ва данного товара"
        );
      }
      if (products.nickname && !pay.nickname) {
        return NotificationManager.error("Введите никнейм");
      }
      setPaymentPending(true);
      createOrder(pay)
        .then((res) => {
          if (res.link) {
            if (pay?.type == "online") {
              setUrl(res.link);
              setShowBank(true);
            }
            else {
              window.open(res.link, '_blank');
              setPaymentPending(false);
            }

          } else {
            setPaymentPending(false);
            NotificationManager.success("Куплено");
          }

          resetPay({ ...pay, nickname: null, count: 1, type: null });
          getPage();
          dispatch(refreshAuth());
          if (!data?.id) {
            getMessages(data)
              .then((res) => {
                setMessages((prev) => ({
                  ...prev,
                  loading: false,
                  items: res.messages.items,
                  dialogId: res.dialog.id,
                  dialog: res.dialog,
                }));
                setValue("id", res.dialog.id);
              })
              .catch(() => setMessages((prev) => ({ ...prev, loading: false })));
          }
        })
        .catch((err) => {
          setPaymentPending(false);
          NotificationManager.error(
            err?.response?.data?.error ?? "Ошибка при покупке"
          );
        });
    },
    [products]
  );

  const user =
    messages?.dialog ? (userId == messages?.dialog?.to?.id
      ? messages?.dialog?.from
      : messages?.dialog?.to)
      : products?.items?.user

  const onTask = useCallback(() => {
    createTask({ type: "report", userId: user.id, dialogId: data.id })
      .then(() => {
        NotificationManager.success("Жалоба отправлена");

      })
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при отправке"
        );
      });
  }, [user]);
  if (products.loading) {
    return <Loader full />;
  }
  return (
    <main>
      <Meta title="Лот" />
      <section className="lot-page mb-6">
        <Container>
          <button
            type="button"
            className="color-2 d-flex align-items-center mb-3"
            onClick={() => navigate(-1)}
          >
            <PiCaretLeftLight className="fs-15" />
            <span className="ms-2">Назад в каталог</span>
          </button>

          <div className="d-flex justify-content-between align-items-center mb-4">
            <h4 className="mb-0 ms-3">— {products?.items?.category?.title}</h4>
            <div className="d-flex align-items-center">
              {/* Количество просмотров */}
              {/* <div className="d-flex align-items-center">
                <LuEye className="fs-13 color-1 me-1" />
                <span>{products?.items?.view}</span>
              </div> */}
              {/* Количество покупок */}
              {/* <div className="d-flex align-items-center ms-4">
                <LuCircleDollarSign className="fs-13 color-1 me-1" />
                <span>157</span>
              </div> */}
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-4 ms-4">
            {products?.items?.status == 0 && <h4 className="mb-0 ms-3">Закрыт</h4>}
            {products?.items?.status == -1 && <h4 className="mb-0 ms-3">Заблокирован</h4>}
          </div>
          <Row className="gx-4 gx-xl-5 gy-5 mb-4">

            <Col xs={12} lg={7}>
              <Row className="g-3 g-sm-4">
                {products?.items?.param &&
                  <>
                    <Col md={3}>{products?.items?.param?.title}</Col>
                    <Col md={9}>

                    </Col>
                  </>
                }
                {products?.items?.region && products?.items?.region.status ?
                  <>
                    < Col md={3}>Регион:</Col>
                    <Col md={9}>
                      {products?.items?.region?.title}
                    </Col>
                  </> : ""
                }

                {products?.items?.server && !products?.items?.param?.data?.serverView &&
                  <>
                    <Col md={3}>Сервер:</Col>
                    <Col md={9}>
                      {products?.items?.server?.title}
                    </Col>
                  </>
                }
                {products?.items?.desc &&
                  <>
                    <Col md={3}>Описание:</Col>
                    <Col md={9}>
                      <p className="desc">{products?.items?.desc}</p>
                      {/* {products?.items?.category?.media && (
                            <img
                            src={getImageURL(products?.items?.category?.media)}
                            alt="AFK Arena"
                            />
                        )} */}
                    </Col>
                  </>
                }
                {products?.items?.options?.length > 0 &&
                  products?.items?.options?.sort((a, b) => a.priority - b.priority && a.id - b.id).map((e) => {
                    let name;
                    if (!e.option.parent) {
                      name = products.items.param.options.find(item => (e?.option?.parent && item.id == e.option.parent));
                      return <>
                        <Col md={3}>{e.value ? e?.option?.title : name?.title ? name.title : "Хакатеристика"} </Col>
                        <Col md={9}>
                          {e.value ? <span className="ms-2">{e?.value}</span> : <span>{e?.option?.title}</span>}
                        </Col>
                      </>
                    }
                    else {
                      if (e.value) {
                        return <>
                          <Col md={3}>{e.value ? e?.option?.title : name?.title ? name.title : "Хакатеристика"} </Col>
                          <Col md={9}>
                            {e.value ? <span className="ms-2">{e?.value}</span> : <span>{e?.option?.title}</span>}
                          </Col>
                        </>
                      }
                      else {
                        return (
                          <>
                            <Col md={3}>{products?.items?.param.options.find(item => item.id == e.option.parent)?.title ?? "Хакатеристика"} </Col>



                            <Col md={9}>
                              <span>{e?.option?.title}</span>
                            </Col>

                          </>
                        )
                      }
                    }


                  })}
                <Col md={3}>Дата размещения</Col>
                <Col md={9}>
                  <div className="date">
                    <time>
                      {moment(products?.items?.createdAt).format("kk:mm")}
                    </time>
                    <time className="ms-3">
                      {moment(products?.items?.createdAt).format("DD.MM.YYYY")}
                    </time>
                  </div>
                </Col>
                {state?.order &&
                  <>
                    {userId == products?.items?.userId ?
                      <Col md={3}>Дата продажи</Col>
                      :
                      <Col md={3}>Дата покупки</Col>
                    }

                    <Col md={9}>
                      <div className="date">
                        <time>
                          {moment(products?.createdAt).format("kk:mm")}
                        </time>
                        <time className="ms-3">
                          {moment(products?.createdAt).format("DD.MM.YYYY")}
                        </time>
                      </div>
                    </Col>
                  </>}
                {products?.items?.status > 0 && !state?.order && userId != products?.items?.userId &&
                  <>
                    <Col md={3}>Способ оплаты:</Col>
                    <Col md={9}>
                      <Select
                        value={pay.type}
                        className={"me-md-4"}
                        title="Выберите способ оплаты"
                        onClick={(e) => setValuePay("type", e.value)}
                        data={[
                          { value: "online", title: "СБП" },
                          { value: "card", title: "Банковская карта" },
                          { value: "wallet", title: "Онлайн кошелек" },
                        ]}
                      />
                    </Col>
                  </>
                }
                {!products?.items?.param?.data?.one &&
                  <>
                    {state?.order ?
                      <>
                        <Col md={3} className="d-flex align-items-center">Количество:</Col>
                        <Col md={9} className="d-flex align-items-center">
                          <span>{products?.count}</span>
                          {products?.items?.data?.typeCount && <span>{products?.items?.data?.typeCount}.</span>}
                        </Col>

                        <Col md={3} className="d-flex align-items-center">Cумма:</Col>
                        <Col md={9} className="d-flex align-items-center">
                          <span>{customPrice(userId == products?.items?.userId ? products?.price : products?.total)}</span>
                        </Col>

                      </>
                      :
                      products?.items?.status > 0 && userId != products?.items?.userId &&
                      <>
                        <Col md={2} className="d-flex align-items-center">Количество:</Col>
                        <Col md={4} className="d-flex align-items-center">
                          <Input
                            value={pay.count}
                            placeholder={'0'}
                            className="me-4"
                            type="number"
                            onChange={(e) => { if (parseInt(e) <= products?.items?.count || e == "") setValuePay("count", parseInt(e)) }}
                            max={products?.items?.count}
                          />
                          {products?.items?.data?.typeCount && <span>{products?.items?.data?.typeCount}.</span>}
                        </Col>
                      </>
                    }
                    {!state?.order &&
                      <>
                        <Col md={2} className="d-flex align-items-center">
                          <p className="text-end">Доступно:</p>
                        </Col>
                        <Col md={4} className="d-flex align-items-center">
                          <span>{products?.items?.count}</span>
                          {products?.items?.data?.typeCount && <span className="ms-2">{products?.items?.data?.typeCount}.</span>}</Col>
                      </>
                    }
                  </>
                }
                {products?.items?.data?.minCount &&
                  <Col className="d-flex align-items-center achromat-3" md={12}>
                    <span className="me-2">Минимум</span>
                    <span className="me-2">{products?.items?.data?.minCount}</span>
                    {products?.items?.data?.typeCount && <span className="me-2">{products?.items?.data?.typeCount}.</span>}
                    <span className="me-2">(требование продавца)</span>
                  </Col>
                }
                {products?.nickname && userId != products?.items?.userId &&
                  (state?.order ?
                    <>
                      <Col md={3} className="d-flex align-items-center">Nickname:</Col>
                      <Col md={9}>
                        <span>{products?.nicknameUser}</span>
                      </Col>
                    </>
                    :
                    products?.items?.status > 0 &&
                    <>
                      <Col md={3} className="d-flex align-items-center">Nickname:</Col>
                      <Col md={9}>
                        <Input
                          value={pay?.nickname ?? ""}
                          placeholder={'nickname'}
                          className="me-4"
                          type="text"
                          onChange={(e) => setValuePay("nickname", e)}
                          maxLength={100}
                        />
                      </Col>
                    </>
                  )
                }
                {userId != products?.items?.userId && !state?.order && products?.items?.status > 0 &&
                  <Col md={4}>
                    <button
                      disabled={!userId || isPaymentPending || pay.count < products?.items?.data?.minCount || !pay.count}
                      onClick={status ? handleSubmitPay(onPay) : handleShowAdvice}
                      type="button"
                      className="btn-5 w-100"
                    >
                      Оплатить{" "}
                      {customPrice((products?.items?.total < 1 ? (Math.round(products?.items?.total * (pay.count ?? 1) * 10000) / 10000) : (Math.round(products?.items?.total * (pay.count ?? 1) * 100) / 100)))}
                    </button>
                  </Col>
                }
                {userId != products?.items?.userId && products?.items?.status > 0 &&
                  < Col md={8}>
                    <p className="achromat-3">
                      * Продавец не сможет получить оплату до тех пор, пока вы не подтвердите выполнение всех его обязательств
                    </p>
                  </Col>
                }
                {userId == products?.items?.userId && !state?.order &&
                  <Col md={5}>

                    <Link to={`/account/offers/edit/${products?.items?.uid}`}
                      className="btn-5"
                    >
                      Редактировать
                    </Link>
                  </Col>
                }
              </Row>
            </Col>
            {userId != products?.items?.user?.id && (
              <Col xs={12} lg={5}>

                {userId == products?.author?.id ? <h4 className="text-start mb-3">Чат с покупателем</h4> :
                  <h4 className="text-start mb-3">Чат с продавцом</h4>}
                {
                  products.loading ? (
                    <div className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
                      Загрузка чата...
                    </div>
                  ) : (
                    <Chat
                      onTask={(e) => onTask(e)}
                      user={user}
                      messages={messages}
                      emptyText="Нет сообщений"
                      onSubmit={(e) => onNewMessage(e)}
                      onChange={(e) => setValue("text", e)}
                      data={data}
                      setImage={(e) => setValue("media", Array.from(e))}
                    />
                  )}

              </Col>
            )}
            <Col xs={12} lg={7}>
              {userId != products?.author?.id &&
                <>
                  <h3 className="mb-3">Продавец - <Link className="color-1" to={`/profile/${products?.items?.userId ?? products?.author?.id}`}>{products?.items?.user?.nickname ?? products?.author?.nickname}</Link></h3>
                  <div className="d-flex align-items-center">
                    <StarRating value={products?.items?.user?.rating ?? products?.author?.rating} />
                    <span className="ms-3 fs-12 fw-5">{(products?.items?.user?.rating != null || products?.author?.rating != null) ? parseFloat(products?.items?.user?.rating ?? products?.author?.rating).toFixed(1) : "0.0"}</span>
                  </div>
                </>}
              <ul className="list-unstyled mt-4">
                {products?.reviews?.length > 0 ? (
                  products.reviews.map((review) => (
                    <li className="mb-3">
                      <ReviewCard {...review} />
                    </li>
                  ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <p>Нет отзывов</p>
                  </div>
                )}
              </ul>
            </Col>
          </Row>

          <Modal show={showShare} onHide={setShowShare} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <h4 className="mb-3">Профиль продавца</h4>
              <Input
                onClick={(e) => e.target.select()}
                readOnly
                defaultValue={`${process.env.REACT_APP_SITE_URL}/profile/${products?.items?.userId}`}
              />
            </Modal.Body>
          </Modal>
          <Modal show={showAdvice} onHide={handleCloseAdvice} size={"md"} centered>
            <Modal.Body>

              <h5 className="text-center">Пожалуйста, потвердите почту.</h5>
              <div className="d-flex justify-content-center mt-4">
                <button type="button" className="btn-4" onClick={handleCloseAdvice}>
                  Отмена
                </button>

                <Link to="/activate" onClick={handleCloseAdvice} className="btn-1 ms-4">
                  Хорошо
                </Link>

              </div>
            </Modal.Body>
          </Modal>
          <Modal show={showBank} onHide={handleCloseBank} centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center justify-content-between mb-4">
                <iframe
                  ref={iframeRef}
                  title="Payment Frame"
                  className="payment-frame"
                  src={url}
                />
              </div>
            </Modal.Body>
          </Modal>


        </Container>
      </section >
    </main >
  );
};

export default LotPage;