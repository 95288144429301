import React, { useEffect, useState } from 'react';
import {ImStarFull} from 'react-icons/im'

const InputRating = ({ className, onChange, defValue=0 }) => {
  const [ratingValue, setRatingValue] = useState(defValue);
  const isSelected = (v) => (v === ratingValue ? 'active' : '')
  useEffect(() => {
    onChange && onChange(ratingValue)
  }, [ratingValue])
  return (
    <div className={'input-rating ' + className}>
      <div className="stars">
        <button
          type="button"
          onClick={() => setRatingValue(5)}
          className={isSelected(5)}
        >
          <ImStarFull />
        </button>
        <button
          type="button"
          onClick={() => setRatingValue(4)}
          className={isSelected(4)}
        >
          <ImStarFull />
        </button>
        <button
          type="button"
          onClick={() => setRatingValue(3)}
          className={isSelected(3)}
        >
          <ImStarFull />
        </button>
        <button
          type="button"
          onClick={() => setRatingValue(2)}
          className={isSelected(2)}
        >
          <ImStarFull />
        </button>
        <button
          type="button"
          onClick={() => setRatingValue(1)}
          className={isSelected(1)}
        >
          <ImStarFull />
        </button>
      </div>
    </div>
  );
};

export default InputRating;