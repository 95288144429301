import React, { useCallback, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import NavPagination from "../../components/NavPagination";
import OfferLine3 from "../../components/OfferLine3";
import ReturnTitle from "../../components/utils/ReturnTitle";
import { editOrder, getOrders } from "../../services/order";
import Loader from "../../components/utils/Loader";
import Meta from "../../components/Meta";
import { useForm } from "react-hook-form";
import { editReview } from "../../services/review";
import { NotificationManager } from "react-notifications";

const PurchaseHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const [orders, setOrders] = useState({
    loading: true,
    items: [],
  });
  const getPage = () => {
    getOrders({ page: currentPage, authorId: 0, size: 10 })
      .then((res) => {
        setOrders((prev) => ({
          prev,
          loading: false,
          ...res,
        }));
        setCurrentPage(res.pagination.currentPage);
      })
      .catch(() => setOrders((prev) => ({ ...prev, loading: false })));
  };
  useEffect(() => {
    getPage();
  }, [currentPage]);
  const onStatus = useCallback((status) => {
    editOrder(status)
      .then((res) => {
        getPage();
        NotificationManager.success("Покупка подтверждена");

      })
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Неизвестная ошибка при отправке"
        );
      });

  }, []);


  const onClick = useCallback((data) => {
    if (!data.value || data.value <= 0) {
      return NotificationManager.error(
        "Укажите оценку"
      )
    }
    editReview(data)
      .then(() => {
        NotificationManager.success("Отзыв отправлен");
      })
      .catch(
        (err) =>
          err &&
          NotificationManager.error(
            err?.response?.data?.error ?? "Неизвестная ошибка при отправке"
          )
      );
  }, [])


  if (orders.loading) {
    return <Loader full />;
  }
  return (
    <div className="main">
      <Meta title="История покупок" />
      <ReturnTitle link={"/account"} title={"История покупок"} />
      <Table className="my-4" borderless responsive>
        <thead>
          <tr>
            <th>Дата</th>
            <th>ID заказа</th>
            <th>Детали</th>
            <th>Продавец</th>
            <th>Статус</th>
            <th>Цена</th>
          </tr>
        </thead>
        <tbody>
          {
            orders?.pagination?.totalItems > 0
              ? (
                orders.loading ?
                  <tr className="py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
                    Загрузка историй...
                  </tr>
                  : (
                    orders?.items.map((item, index) => (
                      <OfferLine3 key={index} {...item} onStatus={onStatus} onClick={onClick} />
                    ))
                  )
              )
              : (
                <h3>Нет покупок</h3>
              )
          }
        </tbody>
      </Table>
      <NavPagination totalPages={orders?.pagination?.totalPages} onPageChange={onPageChange} />
    </div>
  );
};

export default PurchaseHistory;

