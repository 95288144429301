import React, { useCallback, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Input from "../../components/utils/Input";
import { login } from "../../services/auth";

const Login = () => {
  const auth = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.isAuth) {
      return navigate("/");
    }
  }, [auth.isAuth]);

  const {
    control,
    register,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "all", reValidateMode: "onSubmit" });
  const data = useWatch({ control });

  const dispatch = useDispatch();

  const onSubmit = useCallback((data) => {
    dispatch(login(data));
  }, []);
  return (
    <main>
      <Meta title="Авторизация" />
      <Container>
        <section className="pt-4 pt-sm-5 mb-6">
          <Row className="justify-content-center">
            <Col xs={12} lg={10} xl={8} xxl={7}>
              <h1 className="text-center">Вход</h1>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="g-3 g-sm-4 align-items-center">
                  <Col md={4}>
                    <h6 className="mb-0">Email или никнейм:</h6>
                  </Col>
                  <Col md={8}>
                    <Input
                      autoComplete="new-password"
                      type="login"
                      // label="Имя пользователя или Email"
                      name="login"
                      placeholder="Введите имя пользователя или email"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Введите имя пользователя или email",
                        maxLength: {
                          value: 250,
                          message: "Максимально 250 символов",
                        },
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <h6 className="mb-0">Пароль:</h6>
                  </Col>
                  <Col md={8}>
                    <Input
                      autoComplete="new-password"
                      className="mb-2"
                      // label="Пароль"
                      type="password"
                      name="password"
                      errors={errors}
                      placeholder="Введите пароль"
                      register={register}
                      validation={{
                        required: "Введите пароль",
                        minLength: {
                          value: 4,
                          message:
                            "Минимальный пароль должен состоять из 4-ех символов",
                        },
                      }}
                    />
                  </Col>
                </Row>

                {/* <ReCAPTCHA
                  className="mt-4 d-flex justify-content-center w-100"
                  sitekey={process.env.REACT_APP_CAPTCHA}
                  onChange={(e) => setCaptcha(e)}
                /> */}
                <div className="mt-4 d-flex align-items-center justify-content-between">
                  <label>
                    <input
                      type="checkbox"
                      checked={data?.computer}
                      onChange={e => setValue("computer", e.target.checked ? true : false)}
                    />
                    <span>Чужой компьютер</span>
                  </label>
                  <Link to="/password" className="achromat-1 fw-5">
                    Забыли пароль?
                  </Link>
                </div>
                <button
                  type="submit"
                  disabled={!isValid}
                  className="btn-5 fs-13 px-5 mt-4 mx-auto"
                >
                  Войти
                </button>
              </form>
              <h6 className="mt-4 mt-sm-5 text-center">
                У Вас еще нет аккаунта?
                <Link to="/registration" className="color-1 ms-1">
                  Зарегистрироваться
                </Link>
              </h6>
            </Col>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default Login;
