import React from 'react';
import Container from 'react-bootstrap/Container';
import bgImg from '../assets/imgs/bg/gradient.jpg';
import { Row } from 'react-bootstrap';

const Requisites = () => {
  return (
    <main>
      <Container>
        <div className='rules'>
          <div className='top'>
            <h2>Реквизиты</h2>
          </div>
          <div className='content'>
            <span>ИП Разуваева Елена Сергеевна</span>
            <span>Номер счета: 40802810132250006573</span>
            <span>ИНН: 323203455433</span>
            <span>ОГРН: 324320000033480</span>
            <span>Юридический адрес: 241035, РОССИЯ, Брянская, Брянск, Улица Медведева, д. 9а, c. 1, квартира/офис 7</span>

            <span>Почта: speedytrade@mail.ru</span>
          </div>
        </div>
      </Container >
    </main >
  );
};

export default Requisites;