import React, { useCallback, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";

import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import NavPagination from "../../components/NavPagination";
import OfferLine2 from "../../components/OfferLine2";
import Loader from "../../components/utils/Loader";
import ReturnTitle from "../../components/utils/ReturnTitle";
import { getUserProducts } from "../../services/product";

const Offers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState(1);
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
    getPage(status, page.selected + 1);
  };
  const [products, setProducts] = useState({
    loading: true,
    items: [],
  });
  const getPage = useCallback((newStatus, page) => {
    // Определяем целевую страницу и статус
    const targetPage = page || currentPage; // Используем переданную страницу или текущую
    let targetStatus = status; // Используем переданный статус или текущий
    if (newStatus || newStatus == 0) {
      setStatus(newStatus);
      targetStatus = newStatus;
      setCurrentPage(page);
    }
    // Запрос данных с новыми параметрами
    getUserProducts({ page: targetPage, size: 10, status: targetStatus })
      .then((res) => {
        // Обновляем состояние только один раз
        setProducts((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }));
        setCurrentPage(res.pagination.currentPage);
      })
      .catch(() => setProducts((prev) => ({ ...prev, loading: false })));

  }, [currentPage, status]);

  useEffect(() => {
    getPage();
  }, []);


  if (products.loading) {
    return <Loader full />;
  }
  return (
    <div className="main">
      <Meta title="Мои объявления" />
      <ReturnTitle link={"/account"} title={"Мои объявления"} />
      <Row className="g-3 g-lg-4 ">
        <Col md={12}>
          <Link to="add" className="btn-5">
            + Разместить новое объявление
          </Link>
        </Col>
        <Col xxl={2} xl={2} xs={12} sm={6} md={3}>
          <button
            type="button"
            className={`game-btn ${status == 1 ? 'active' : ''}`}
            onClick={() => getPage(1, 1)}
          >
            <h6 className="mb-0">Активные</h6>
          </button>
        </Col>
        <Col xxl={2} xl={3} xs={12} sm={6} md={3}>
          <button
            type="button"
            className={`game-btn ${status == 0 ? 'active' : ''}`}
            onClick={() => getPage(0, 1)}
          >
            <h6 className="mb-0">Неактивные</h6>
          </button>
        </Col>
        <Col xxl={3} xl={3} xs={12} sm={8} md={4}>
          <button
            type="button"
            className={`game-btn ${status == -1 ? 'active' : ''}`}
            onClick={() => getPage(-1, 1)}
          >
            <h6 className="mb-0">Заблокированные</h6>
          </button>
        </Col>
      </Row>

      {/* <Row xs={1} sm={3} className="gy-3 gy-sm-0 gx-3 gx-md-4 mt-4">
        <Col className="d-xl-flex align-items-center">
          <span className="me-3">Игра:</span>
          <select defaultValue={""}>
            <option value={""}>Все игры</option>
          </select>
        </Col>
      </Row> */}

      <Table className="my-4" borderless responsive>
        <thead>
          <tr>
            <th>Название&nbsp;игры</th>
            <th>Сервер</th>
            <th >Описание</th>
            <th>Количество</th>
            <th>Цена</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {products.loading ? (
            <tr>
              <td className="py-5 text-center text-muted">
                Загрузка историй...
              </td>
            </tr>
          ) : (
            products?.items.map((item) => <OfferLine2 {...item} />)
          )}
        </tbody>
      </Table>

      <NavPagination
        currentPage={currentPage - 1}
        totalPages={products?.pagination?.totalPages}
        onPageChange={onPageChange}
      />
    </div >
  );
};

export default Offers;
