import React from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

const Documents = () => {
  return (
    <main>
      <Container>
        <div className='rules'>
          <div className='top'>
            <h2>Документы</h2>
          </div>
          <div className='content'>
            <ul className=''>
              <li><Link to='/privacy' target="_blank">Политика конфиденциальности</Link></li>
              <li><Link to='/rules' target="_blank">Правила Сайта</Link></li>
              <li><Link to='/agency' target="_blank">Агентский договор</Link></li>
              <li><Link to='/public-offer' target="_blank"> Публичная оферта безопасной сделки</Link></li>
              <li><Link to='/license-agreement' target="_blank">Лицензионное соглашение</Link></li>
              <li><Link to='/requisites' target="_blank">Реквизиты</Link></li>
            </ul>
          </div>
        </div>
      </Container >
    </main >
  );
};

export default Documents;