import React from 'react';
import BlogCard from './BlogCard';
import Loader from './utils/Loader';

const BlogSection = ({ articles }) => {

  return (
    <section className='sec-blog'>
      <ul className='blog-list list-unstyled'>
        {articles?.length > 0 && articles.map((item, index) => (
          <li key={index}>
            <BlogCard {...item} />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default BlogSection;