import React from 'react'

function GameMini({title, imgFull}) {
    return (
        <figure className="game-small">
            <img src={imgFull} alt={title} />
            <figcaption>
                {title}
                {/* {props.url ? (
                    <a href={props.url} className="stretched-link">
                        {title}
                    </a>
                ) : (
                    title
                )} */}
            </figcaption>
        </figure>
    )
}

export default GameMini