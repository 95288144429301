import moment from "moment";
import React, { memo, useCallback, useState } from "react";
import { FiSend } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getImageURL } from "../../helpers/all";
import Loader from "../utils/Loader";
import SimpleInputFile from "../utils/SimpleInputFile";
import Message from "./Message";
import { LuChevronLeft, LuAlertTriangle, LuMoreHorizotnal, LuTrash2, LuMoreHorizontal } from "react-icons/lu";
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineStop } from "react-icons/ai";
import { GoReport } from "react-icons/go";

const Chat = memo(({ general, messages, emptyText, onChange, className, onSubmit, user, type, account, setImage, data, onTask }) => {
  const userId = useSelector((state) => state.auth?.user?.id);
  const [text, setText] = useState();
  const [rows, setRows] = useState(1);
  const image = getImageURL({ path: user?.media });
  const status = useSelector(state => state.auth?.user?.status);
  const navigate = useNavigate();

  const onChangeText = (e) => {
    setText(e);
    onChange(e);
  };
  const onKeyPress = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      const textarea = e.target;
      setRows(Math.min(Math.max(textarea.value.split('\n').length, 1), 4));
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onClick();
    }
  };

  const onClick = useCallback(() => {
    if (text && text?.length > 0 || data?.media?.length > 0) {
      onSubmit(text);
      setText("");
      setRows(1);
    }
  }, [text, data]);

  if (messages.loading) {
    return <Loader />;
  }
  return (
    <div className={general == "general" ? "chat w-100" : "minH-100 w-100"}>
      {general == "general" &&
        <div className="chat-title">
          {account &&
            <button type="button" onClick={() => navigate(-1)}>
              <LuChevronLeft className="fs-15 me-sm-1" />
              <span className="d-none d-sm-inline">Назад</span>
            </button>
          }
          <h3 className="mb-0 ms-4">Общий-чат</h3>
          <p className="d-none d-sm-block ms-4">Продажа в чате запрещена</p>
          {/* <div className="sec-chat-count">
          <div className="num">{messages.count}</div>
          <div className="text">участника online</div></div> */}
        </div>
      }
      <div className={general == "general" ? "chat-window" : "message-window minH-100"}>
        {general != "general" && user &&
          <div className="top">
            <button type="button" onClick={() => navigate(-1)}>
              <LuChevronLeft className="fs-15 me-sm-1" />
              <span className="d-none d-sm-inline">Назад</span>
            </button>

            <div className="text-center">
              <Link to={"/profile/" + user.id}>
                <h5 className="achromat-2 mb-0 mb-sm-1">{user?.nickname}</h5>
              </Link>
              <div className="achromat-3 fs-09">
                {user?.online?.status ? (
                  <span className="text-success">Онлайн</span>
                ) : user?.online?.end ? (
                  "Был(-а) в сети " +
                  moment(user?.online?.end).fromNow()
                ) : (
                  "Оффлайн"
                )}
              </div>
            </div>

            <div className="d-flex align-items-center">
              {userId &&
                <Dropdown className="me-2 me-sm-4">
                  <Dropdown.Toggle variant="none">
                    <LuMoreHorizontal className="fs-15 achromat-2" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end">
                    <Dropdown.Item as="button" onClick={onTask} >
                      <GoReport />
                      <span>Пожаловаться</span>
                    </Dropdown.Item>
                    {/* <Dropdown.Item as="button">
                      <AiOutlineStop />
                      <span>Заблокировать</span>
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              }
              <div className="img d-none d-sm-block me-sm-3">
                <Link to={"/profile/" + user.id}>
                  <img src={image} alt={user?.nickname} />
                </Link>
                <div className={user?.online?.status ? "indicator online" : "indicator"}></div>
              </div>
            </div>
          </div>
        }

        {messages.loading ? (
          <div className={general == "general" ? "w-100 h-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center" : "middle"} id="chatBody">
            Загрузка сообщений...
          </div>
        ) : messages?.items?.length > 0 ? (
          <div className={general == "general" ? "chat-space" : "middle"} id="chatBody">
            {messages.items.map((item, index) => (
              <Message
                dialog={messages.dialog}
                type={item.type}
                key={index}
                my={item.userId === userId}
                general={general}
                id={item.userId}
                user={item.user}
                time={item.createdAt}
                text={item.text}
                media={item.media}
                status={item.status}
                admin={item.memberId}
                view={item.view}
              />
            ))}
          </div>
        ) : (
          <div className={general == "general" ? "w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center" : "middle"}>
            {emptyText}
          </div>
        )}
        {userId ? status ? (
          <div className={general == "general" ? "chat-window-form" : "form"} >
            {general != "general" &&
              <SimpleInputFile media={data?.media} setImage={(e) => setImage(e)} className="mx-sm-3" />
            }
            <textarea
              rows={rows}
              value={text}
              type="text"
              placeholder={general == "general" ? "Начните общаться" : "Ваше сообщение"}
              onChange={(e) => onChangeText(e.target.value)}
              onKeyPress={onKeyPress}
            />
            {general == "general" && <hr className="vertical mx-2 mx-sm-3" />}
            <button
              onClick={onClick}
              type="submit"
              className=""
            >
              <FiSend />
            </button>
          </div>
        )
          :
          (
            <div className={general == "general" ? "w-100 py-4 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center" : "form"}>
              Для отправки сообщений подтвердите почту!
            </div>
          )
          :
          (
            <div className={general == "general" ? "w-100 py-4 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center" : "form"}>
              Для отправки сообщений войдите в аккаунт!
            </div>
          )}
      </div>
    </div >
  );
});

export default Chat;
