import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBreadcrumbs from '../components/NavBreadcrumbs';
import BlogCard2 from '../components/BlogCard2'
import { getArticles } from '../services/article';
import NavPagination from '../components/NavPagination';
import Loader from '../components/utils/Loader';
import BlogSection from '../components/BlogSection';
import Meta from '../components/Meta';

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const [articles, setArticles] = useState({
    loading: true,
    items: [],
  });
  useEffect(() => {
    getArticles({ page: currentPage, size: 10 })
      .then((res) => {
        setArticles((prev) => ({
          prev,
          loading: false,
          ...res,
        }))
        setCurrentPage(res.pagination.currentPage)
      })
      .catch(() => setArticles((prev) => ({ ...prev, loading: false })));
  }, [currentPage]);

  if (articles.loading) {
    return <Loader full />;
  }
  return (
    <main>
      <Meta title="Новости" />
      <Container>
        <section className='news-all mb-5'>
          <h1>Новости</h1>
          <Row className='gx-4 gx-xl-5'>
            <Col xs={12} lg={7} xxl={8}>
              <ul className='news-list mb-5'>
                {articles?.items?.length > 0 && articles.items.map((item, index) => (
                  <li key={index}>
                    <BlogCard2 {...item} />
                  </li>
                ))}
              </ul>
              <NavPagination totalPages={articles?.pagination?.totalPages} onPageChange={onPageChange} />
            </Col>
            <Col xs={12} lg={5} xxl={4} className='mt-5 mt-lg-0'>
              <div className="box">
                <h3>Популярное</h3>
                <BlogSection articles={articles?.recommends} />
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default Blog;