import React, { useCallback, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useForm, useWatch } from 'react-hook-form';
import { FiChevronDown, FiHelpCircle } from 'react-icons/fi';
import AppealLine from '../../components/AppealLine';
import NavPagination from '../../components/NavPagination';
import Input from '../../components/utils/Input';
import InputFileImg from '../../components/utils/InputFileImg';
import ReturnTitle from '../../components/utils/ReturnTitle';
import { createTask, getTasks } from '../../services/task';
import { NotificationManager } from 'react-notifications';
import Textarea from '../../components/utils/Textarea';
import Loader from '../../components/utils/Loader';
import Meta from '../../components/Meta';
import { useDispatch } from 'react-redux';
import { updateNotification } from '../../store/reducers/notificationSlice';

const Callback = () => {
    const [tab, setTab] = useState(0);
    const [cbSection, setCbSection] = useState(1);
    const isSelected = (v) => (v === cbSection ? 'btn-2 active h-100 p-3 p-sm-4' : 'btn-2 h-100 p-3 p-sm-4')

    const [task, setTask] = useState({
        loading: true,
        items: [],
    });
    const [currentPage, setCurrentPage] = useState(1);
    const onPageChange = (page) => {
        setCurrentPage(page.selected + 1);
    };
    const { control,
        reset,
        register,
        setValue,
        handleSubmit, } = useForm({
            mode: "all",
            reValidateMode: "onSubmit",
            defaultValues: {
                type: "task",
                loading: false,
            },
        });

    const data = useWatch({ control });
    const dispatch = useDispatch();
    const getPage = () => {
        getTasks({ type: "task", page: currentPage, size: 10 })
            .then((res) => {
                setTask((prev) => ({
                    ...prev,
                    loading: false,
                    ...res
                }));
                dispatch(updateNotification({ notification: -1 }));
            })
            .catch(() => setTask((prev) => ({ ...prev, loading: false })));
    };
    useEffect(() => {
        getPage();
    }, [currentPage]);
    const onTask = useCallback(() => {
        if (!data.title) {
            return NotificationManager.error("Напишите тему");
        }
        if (!data.comment) {
            return NotificationManager.error("Поле сообщение не может быть пустым");
        }
        setValue("loading", true)
        createTask({ ...data })
            .then(() => {
                reset({ ...data, comment: "", title: "", file: null, loading: false })
                getPage();
                NotificationManager.success("Тикет отправлен");

            })
            .catch((err) => {
                reset({ ...data, loading: false })
                NotificationManager.error(
                    err?.response?.data?.error ?? "Ошибка при отправке"
                );
            });
    }, [data]);
    if (task.loading) {
        return <Loader full />;
    }
    return (
        <div className="main">
            <Meta title="Помощь" />
            <ReturnTitle link={'/account'} title={'Помощь'} />

            <div className="tabs-group mb-4 mb-sm-5">
                <button type="button" className={tab === 0 ? 'active' : ''} onClick={() => setTab(0)}>
                    Тикеты
                </button>
                {/* <button type="button" className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
                    Центр помощи
                </button> */}
                <button type="button" className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
                    Правила
                </button>
            </div>


            {
                tab === 0 && (
                    <div>
                        <h6>Создать новый тикет</h6>
                        <div className="achromat-3 d-flex align-items-start">
                            <FiHelpCircle className="fs-13" />
                            <p className="flex-1 ms-3">
                                Если ваш вопрос технического плана, то желательно предоставить как можно более подробную
                                информацию о проблеме. Пожалуйста, опишите, какие действия совершались до возникновения
                                вопроса, а также, по возможности, приложите необходимые скриншоты. Данная информация
                                значительно ускорит время ответа и избавит от лишних вопросов. Спасибо!
                            </p>
                        </div>
                        <div className="mt-4 mt-sm-5">
                            <Row className="g-3 g-sm-4">
                                <Col md={2}>
                                    <div>Тема:</div>
                                </Col>
                                <Col md={10}>
                                    <Input
                                        type="text"
                                        name="title"
                                        defaultValue={data.title}
                                        register={register}
                                    />
                                </Col>
                                <Col md={2}>
                                    <div>Сообщение:</div>
                                </Col>
                                <Col md={10}>
                                    <Textarea
                                        className="mb-3"
                                        type="text"
                                        defaultValue={data?.comment}
                                        placeholder={'Предоставьте как можно более подробную информацию, приложите необходимые скриншоты.'}
                                        name="comment"
                                        register={register}
                                    />
                                    <InputFileImg media={data?.file} setImage={(e) => setValue("file", Array.from(e)[0])} />
                                </Col>
                            </Row>
                            <button disabled={data.loading} type='submit' className='btn-5 mt-4' onClick={handleSubmit(onTask)} >Отправить тикет</button>
                        </div>

                        <h6 className="mt-5">Ваши тикеты</h6>

                        <Table responsive borderless>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Тема</th>
                                    <th>Последнее сообщение</th>
                                    <th>Статус</th>
                                </tr>
                            </thead>
                            <tbody>
                                {task?.items?.length > 0 && task.items.map((item) => (
                                    <AppealLine {...item} />
                                ))}
                            </tbody>
                        </Table>
                        <NavPagination totalPages={task?.pagination?.totalPages} onPageChange={onPageChange} />
                    </div>
                )
            }

            {/* {
                tab === 1 && (
                    <div>
                        <h5 className="fw-4">Продажа</h5>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <FiHelpCircle className="me-4" />
                                    <span>Как продать?</span>
                                    <FiChevronDown className="chevron" />
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <FiHelpCircle className="me-4" />
                                    <span>Что делать, если не удалось продать?</span>
                                    <FiChevronDown className="chevron" />
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    <FiHelpCircle className="me-4" />
                                    <span>Самые популярные вопросы, возникающие во время продажи</span>
                                    <FiChevronDown className="chevron" />
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <h5 className="fw-4 mt-4 mt-sm-5">Покупка</h5>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <FiHelpCircle className="me-4" />
                                    <span>Как купить?</span>
                                    <FiChevronDown className="chevron" />
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <FiHelpCircle className="me-4" />
                                    <span>Что делать, если не удалось купить?</span>
                                    <FiChevronDown className="chevron" />
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    <FiHelpCircle className="me-4" />
                                    <span>Самые популярные вопросы, возникающие во время покупки</span>
                                    <FiChevronDown className="chevron" />
                                </Accordion.Header>
                                <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                )
            } */}

            {
                tab === 2 && (
                    <div>
                        <h5 className="color-1">Правила Сайта</h5>
                        <p className="mb-3">Настоящий документ (далее — Правила Сайта, Правила, Правила Сервиса), является частью публичной оферты, размещенной по адресу https://speedytrade.ru/rules. Раскрытие всех терминов и определений, общих для Правил и Соглашения, приведено в Соглашении.</p>
                        <Table responsive striped borderless>
                            <thead>
                                <tr>
                                    <th>Нарушение</th>
                                    <th>Санкции</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        1. Запрещается размещать на сайте заведомо ложную информацию о товарах или услугах, их наличии, качестве, количестве, характеристиках.
                                    </td>
                                    <td>
                                        Предупреждение, блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2. Запрещается вводить в заблуждение, обманывать пользователей и администрацию Сайта.
                                    </td>
                                    <td>
                                        Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        3. Запрещается накручивать количество совершенных сделок и отзывов. Безосновательно изменять отзывы по старым заказам.
                                    </td>
                                    <td>
                                        Удаление отзыва. Удаление всех отзывов. Блокировка аккаунта при повторном нарушении.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        4. Запрещается отправлять пользователям личные данные, контакты и ссылки на сторонние сайты и сервисы любыми способами.
                                    </td>
                                    <td>
                                        Предупреждение. Блокировка аккаунта и отказ в выплатах при повторном нарушении.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        5. Передача третьим лицам какой-либо информации о пользователях или сделках (совершенных или несовершенных) с целью нанесения вреда пользователям.
                                    </td>
                                    <td>
                                        Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        6. Размещение в любом виде порнографической, противозаконной и политической информации.
                                    </td>
                                    <td>
                                        Удаление информации и сообщений. При повторном нарушении блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        7. Запрещается осуществлять обмен денежных средств из одной платёжной системы в другую с целью совершения финансовых махинаций.
                                    </td>
                                    <td>
                                        Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        8. Запрещается размещать объявления на других площадках.
                                    </td>
                                    <td>
                                        Предупреждение, блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        9. Запрещается продажа товаров, полученных посредством обмана других пользователей или полученных любым другим незаконным путем.
                                    </td>
                                    <td>
                                        Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        10. Запрещена продажа и распространение персональных данных, телефонных номеров, нелицензионного и/или вредоносного ПО, порнографии, эротики, информации связанной со ставками и казино, «рандомов» и любой другой информации, товара или услуги которые противоречат действующим законодательствам.
                                    </td>
                                    <td>
                                        Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        11. Запрещается продажа и распространение обучений о неправомерной деятельности.
                                    </td>
                                    <td>
                                        Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        12. Запрещена продажа товаров, нарушающих исключительные права и лицензионные соглашения третьих лиц без разрешения правообладателя.
                                    </td>
                                    <td>
                                        Блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        13. Запрещается неуважительное общение и угрозы в сторону Администрации сайта
                                    </td>
                                    <td>
                                        Предупреждение, блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        14. Запрещается отправка спама в Службу поддержки сайта
                                    </td>
                                    <td>
                                        Предупреждение, блокировка аккаунта и отказ в выплатах.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        15. Запрещается спам, флуд, ненормативная лексика, оскорбления, политические обсуждения. В общем чате запрещается отправка ссылок на лоты, реклама торговых интернет-ресурсов, а также любые сообщения, нарушающие действующее законодательство.
                                    </td>
                                    <td>
                                        Предупреждение и блокировка сообщений. При многократном нарушении блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        16. Запрещается осуществлять сделки с целью написания негативных отзывов, которые не соответствуют действительности (борьба с конкурентами и тп).
                                    </td>
                                    <td>
                                        Предупреждение, блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        17. Запрещается дублирование объявлений. Размещение объявлений о покупке.
                                    </td>
                                    <td>
                                        Удаление объявлений, предупреждение, блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        18. Запрещается предоставление финансов и/или товара/услуги участнику сделки до момента согласия и подтверждения сделки обеими сторонами, на сторонних ресурсах и/или в авансовом порядке. В этом случае администрация проекта не сможет вернуть средства и аннулировать сделку.
                                    </td>
                                    <td>
                                        Отказ в поддержке и невозможность последующего обращения по сделке в поддержку. Блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        19. Запрещается размещать объявления в непредназначенных для этого разделах.
                                    </td>
                                    <td>
                                        Предупреждение, блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        20. Запрещен призыв к совершению сделок или общению за пределами SpeedyTrade в мессенджерах, социальных сетях, форумах, других площадках.
                                    </td>
                                    <td>
                                        Предупреждение, блокировка аккаунта и отказ в выплатах при повторном нарушении.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        21. Запрещается размещать объявления с не существующими товарами или услугами с целью демпинга и попытки искусственного изменения рыночных цен.
                                    </td>
                                    <td>
                                        Предупреждение, удаление объявлений, блокировка аккаунта.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        22. Запрещается подтверждение сделки до получения товара или услуги в полном объеме.
                                    </td>
                                    <td>
                                        Отказ в поддержке и невозможность последующего обращения по сделке в поддержку. В этом случае администрация проекта не сможет вернуть средства и аннулировать сделку.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        23. Запрещается требовать подтверждение сделки у второй стороны до предоставления услуги в полном объеме
                                    </td>
                                    <td>
                                        Предупреждение, блокировка;
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        В случае блокировки аккаунта для проведения внутреннего расследования, все денежные операции, ожидающие вывода, отменяются и средства возвращаются на внутренний баланс Сервиса в рамках аккаунта замороженного пользователя.
                                        Возврат денежных средств осуществляется при наличии таковых на счете нарушителя, в иных случаях возврат не может быть осуществлен.
                                        Администрация оставляет за собой право обращения в правоохранительные органы для преследования нарушителей и злоумышленников, включая передачу всей персональной информации мошенника.
                                        При нарушении правил сервиса администрация вправе изменять персональные условия пользования площадкой для нарушителя.
                                        Если вы хотите обменяться контактными данными с другим продавцом для совместного выполнения заказов, подайте заявку в службу поддержки.
                                        Администрация оставляет за собой право на связь с продавцами под видом покупателей с целью выявления нарушений.
                                        Администрация оставляет за собой право проводить проверки размещаемых на площадке товаров.
                                        Администрация оставляет за собой право смягчить или отменить любое наложенное наказание, если оно нецелесообразно в каком-либо конкретном случае.
                                        Каждый спор между покупателем и продавцом индивидуален, поэтому окончательное решение о степени ответственности продавца может существенно отличаться от написанного выше.
                                        Договорённости в чате между продавцом и покупателем могут быть приняты во внимание при решении спорных вопросов.

                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        {/* 
                        <button type="button" className="btn-5 fs-11 mt-4 mt-sm-5">
                            Принять условия
                        </button> */}
                    </div>
                )
            }
        </div >
    );
};

export default Callback;