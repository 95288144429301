import React, { useCallback, useEffect, useState } from 'react';
import NavPagination from '../../components/NavPagination';
import OfferLine3 from '../../components/OfferLine3';
import ReturnTitle from '../../components/utils/ReturnTitle';
import { editOrder, getOrders } from "../../services/order";
import Loader from '../../components/utils/Loader';
import Table from 'react-bootstrap/Table';
import { NotificationManager } from 'react-notifications';
import Meta from '../../components/Meta';

const SalesHistory = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const [orders, setOrders] = useState({
    loading: true,
    items: [],
  });
  const getPage = () => {
    getOrders({ page: currentPage, authorId: 1, size: 10 })
      .then((res) => {
        setOrders((prev) => ({
          prev,
          loading: false,
          ...res,
        }))
        setCurrentPage(res.pagination.currentPage)
      })
      .catch(() => setOrders((prev) => ({ ...prev, loading: false })));
  };
  useEffect(() => {
    getPage();
  }, [currentPage]);
  const onStatus = useCallback((status) => {
    editOrder(status)
      .then((res) => {
        getPage();
        NotificationManager.success("Сделка отменена");
      })
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Неизвестная ошибка при отправке"
        );
      });

  }, []);
  if (orders.loading) {
    return <Loader full />;
  }
  return (
    <div className="main">
      <Meta title="История продаж" />
      <ReturnTitle link={'/account'} title={'История продаж'} />

      <Table borderless responsive className="my-sm-4">
        <thead>
          <tr>
            <th>Дата</th>
            <th>ID заказа</th>
            <th>Описание</th>
            <th>Покупатель</th>
            <th>Статус</th>
            <th>Цена</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders.loading ? (
            <tr>
              <td className='py-5 text-center text-muted'>Загрузка историй...</td>
            </tr>
          ) : (
            orders?.items.map((item) => (
              <OfferLine3 {...item} onStatus={onStatus} />
            ))
          )}
        </tbody>
      </Table>

      <NavPagination totalPages={orders?.pagination?.totalPages} onPageChange={onPageChange} />
    </div>
  );
};

export default SalesHistory;