import React, { useCallback, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import { authActivate, authNewKeyActivate, checkAuth, logout, refreshAuth } from "../../services/auth";
import { BiLogOut } from "react-icons/bi";
import { Timer } from "../../helpers/all";

const Activate = () => {
  const [key, setKey] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [endTimer, setEndTimer] = useState(false);

  const onKey = useCallback((key) => {
    setLoading(true);
    authActivate(key)
      .then(() => {
        NotificationManager.success("Ваш аккаунт подтвержден");

        setLoading(false);
        dispatch(refreshAuth());
        navigate("/")
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error === "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
        setLoading(false);
      });
  }, []);
  const getKey = useCallback(() => {
    setEndTimer(false)
    authNewKeyActivate()
      .then(() => {
        NotificationManager.success("Код подтверждения отправлен повторно");

        setLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error === "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
        setLoading(false);
      });
  }, []);
  const auth = useSelector((state) => state?.auth);
  useEffect(() => {
    if (!auth.isAuth) {
      return navigate("/");
    }
  }, [auth.isAuth]);

  return (
    <main>
      <Meta title="Подтверждение почты" />
      <Container>
        <section className="hv-100 sec-login d-flex flex-column align-items-center justify-content-center">
          <h1 className="h3 text-center">Введите код, отправленный на указанную электронную почту</h1>
          <Row className='g-3 g-md-4 mb-5 mt-1  justify-content-center'>

            <input className='code' type="number" placeholder='0000' value={key} onChange={(e) => { e.target.value.length < 5 && setKey(e.target.value) }} />
            {endTimer ? (
              <p className="text-center pointer" onClick={() => getKey()}>
                Отправить повторно код подтверждения
              </p>
            ) : (
              <p className="text-center">
                Повторить отправку кода через <Timer onEnd={() => setEndTimer(true)} /> сек
              </p>
            )}
            <button type='button' className='btn-1 h-100 w-100' disabled={!key || key?.length < 4 || loading} onClick={() => onKey(key)}>Отправить</button>
            <button type='button' className='btn-1 h-100 w-100' onClick={() => dispatch(logout())}>
              Выйти
            </button>
          </Row>
        </section>

      </Container>
    </main>
  );
};

export default Activate;
