import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import Notifications from "./Notifications";
import { useDispatch, useSelector } from "react-redux";
import ThemeToggler from "./utils/ThemeToggler";
import Logo from "../assets/images/speedytradeLogo.svg";

import { LuSearch } from "react-icons/lu";
import { IoIosBookmark } from "react-icons/io";
import { IoCloseCircleOutline, IoArrowUpCircleOutline } from "react-icons/io5";
import useDebounce from "../hooks/useDebounce";
import { getSearch } from "../services/search";
import GameCard from "./GameCard";
import { getFavorites, toggleFavorite } from "../services/favorite";
import { getImageURL } from "../helpers/all";
import { FiMessageCircle } from "react-icons/fi";

const Header = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const user = useSelector((state) => state.auth.user);
  const status = useSelector((state) => state.auth.user.status);
  const favorites = useSelector((state) => state.favorite.items);
  const unreadCount = useSelector((state) => state.notification.message);
  const dispatch = useDispatch();
  const [showAdvice, setShowAdvice] = useState(false);
  const handleCloseAdvice = () => setShowAdvice(false);
  const handleShowAdvice = () => setShowAdvice(true);

  const [showFav, setShowFav] = useState(false);
  const handleCloseFav = () => {
    setShowFav(false);
  }
  const handleShowFav = () => {
    setShowFav(!showFav);
    setShowSearch(false)
    setSearchTerm("")
  }

  const [showSearch, setShowSearch] = useState(false);
  const handleCloseSearch = () => { setShowSearch(false), setSearchTerm("") };
  const handleShowSearch = () => { setShowSearch(true), setShowFav(false); };

  const [searchTerm, setSearchTerm] = useState('');
  const [searchSort, setSearchSort] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 800);

  const [games, setGames] = useState({ items: [], loading: true });;
  useEffect(() => {
    if (!searchTerm) {
      handleCloseSearch();
    }
  }, [searchTerm]);
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 0 && searchTerm == debouncedSearchTerm) {
      getSearch(debouncedSearchTerm)
        .then((res) => {
          setGames(prev => ({
            ...prev,
            items: res,
            loading: false
          }));
          setSearchSort(debouncedSearchTerm);
          handleShowSearch();
        })
        .catch(() => setGames((prev) => ({ ...prev, loading: false })));

    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    if (showFav) {
      dispatch(getFavorites());
    }
  }, [showFav]);
  const onFav = useCallback((e) => {
    dispatch(toggleFavorite({ categoryId: e, }));
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  return (
    <>
      <header id="header">
        <Container>
          <div className="d-flex align-items-center">
            <Link to="/" className="me-4" onClick={() => { setShowSearch(false), setSearchTerm(""), showFav == true && setShowFav(false); }}>
              <img src={Logo} alt="speedytrade" className="logo" />
            </Link>

            <div className="form-search d-none d-md-flex">
              <input
                type="search"
                value={searchTerm}
                placeholder="Поиск по описанию"
                onChange={(event) => {
                  handleSearchChange(event);
                }}
              />
              <div><LuSearch /></div>
            </div>

            <hr className="vertical d-none d-md-block mx-3 mx-xl-4" />
            <button
              type="button"
              onClick={(isAuth && status) ? handleShowFav : handleShowAdvice}
              className="fav-btn d-flex align-items-center"
            >
              <IoIosBookmark />
            </button>
            {isAuth &&
              <Link to="/account/messages" className="ms-lg-5 ms-3 d-none d-md-flex align-items-center" onClick={() => { setShowSearch(false), setSearchTerm(""), showFav == true && setShowFav(false); }}>
                <FiMessageCircle className="fs-12 me-1" />
                <span>
                  Онлайн-чат
                </span>
                {unreadCount && unreadCount > 0 ? <span className="unread-header-conversations-count">{parseInt(unreadCount)}</span> : ""}
              </Link>

            }
          </div>

          <div className="d-flex align-items-center">
            <ThemeToggler />
            <hr className="vertical d-none d-xl-block mx-3 mx-xl-4" />
            {isAuth ? (
              <>
                <Link
                  to="/account"
                  className="user ms-4"
                  onClick={() => { setShowSearch(false), setSearchTerm(""), showFav == true && setShowFav(false); }}
                >
                  <img src={getImageURL({
                    path: user?.media,
                    size: "mini",
                    type: "user",
                  })} className="user__avatar" alt={user?.nickname} />
                  <span className="d-none d-md-block">{user?.nickname}</span>
                </Link>

              </>
            ) : (
              <>
                <Link to="/registration" className="reg  d-none d-lg-block ms-4">
                  Регистрация
                </Link>
                <Link to="/login" className="btn-1 ms-2 ms-sm-3 ms-lg-4">
                  Войти
                </Link>
              </>
            )}
          </div>
        </Container >
      </header >

      <Modal show={showAdvice} onHide={handleCloseAdvice} size={"md"} centered>
        <Modal.Body>
          {status ?
            <h5 className="text-center">Пожалуйста, войдите или зарегистрируйтесь.</h5>
            :
            <h5 className="text-center">Пожалуйста, потвердите почту.</h5>}
          <div className="d-flex justify-content-center mt-4">
            <button type="button" className="btn-4" onClick={handleCloseAdvice}>
              Отмена
            </button>
            {status ?
              <Link to="/login" onClick={handleCloseAdvice} className="btn-1 ms-4">
                Хорошо
              </Link>
              :
              <Link to="/activate" onClick={handleCloseAdvice} className="btn-1 ms-4">
                Хорошо
              </Link>
            }
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas
        show={showFav}
        scroll={true}
        onHide={handleCloseFav}
        onClick={handleCloseFav}
        onEscapeKeyDown={handleCloseFav}
        placement="top"
        className="fav-modal"

      >
        <Offcanvas.Body className="p-0">
          <Container className="px-0" onClick={(e) => e.stopPropagation()}>
            <section className='sec-favorites'>
              <div className='d-flex justify-content-between align-items-center mb-4'>
                {favorites && favorites.length > 0 ?
                  < h3 >Избранное</h3>
                  :
                  <h5 className="mb-0 !important">Вы пока не добавили себе избранных игр.</h5>
                }
                <button type='button' onClick={handleCloseFav} className='d-flex fs-15 achromat-4'>
                  <IoArrowUpCircleOutline />
                </button>
              </div>
              <ul className='list-unstyled row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-3 g-md-4 g-xxl-4'>

                {favorites && favorites.length > 0 && favorites.map((item, i) => {
                  return (
                    <li key={i}>

                      <div className="sec-favorites-item">
                        <Link to={`/${item?.category.uid ? item?.category.uid : item?.category.id}/?${item.regionId ? `region=${item.regionId}&` : ''}${item.paramId ? `param=${item.paramId}` : ''}`} className='title'
                          onClick={handleCloseFav}
                        >{item?.category?.title}</Link>
                        <button onClick={() => onFav(item.categoryId)} type="button">
                          <IoCloseCircleOutline />
                        </button>
                      </div>

                    </li>
                  );
                })
                }
              </ul>

            </section>
          </Container>
        </Offcanvas.Body>
      </Offcanvas >
      <Offcanvas
        show={showSearch}
        onHide={handleCloseSearch}
        onClick={handleCloseSearch}
        onEscapeKeyDown={handleCloseSearch}
        placement="top"
        autoFocus={false}
        className="fav-modal"
        scroll={true}
      >
        <Offcanvas.Body className="p-0">
          <Container className="px-0" onClick={(e) => e.stopPropagation()}>
            <section className='sec-favorites'>
              {games.items.length > 0 ?
                <ul className="list-unstyled gy-3 gx-3 gx-xl-5 row row-cols-lg-4 row-cols-md-3 row-cols-2">
                  <GameCard param2={games.items} term={searchSort} onSearch={() => { handleCloseSearch() }} />
                </ul>
                :
                <h5 className="mb-0 !important">Мы не смогли найти такую игру.</h5>
              }
            </section>
          </Container>
        </Offcanvas.Body>
      </Offcanvas >
    </>
  );
};

export default Header;
