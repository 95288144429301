import React from 'react';
import {Link} from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

const ReturnTitle = ({link="", title="", className="mb-4"}) => {
  return (
    <div className={"d-flex align-items-center " + className}>
      <Link to={link} className='btn-1 p-2 me-4 d-lg-none'>
        <FiArrowLeft className="fs-15"/>
      </Link>
      <h4 className="color-1 mb-0">{title}</h4>
    </div>
  );
};

export default ReturnTitle;