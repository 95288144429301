import React, { useState } from "react";
import MainSlide from "./MainSlide";
import GameLarge from "./GameLarge";
import GameMini from "./GameMini";

import useIsMobile from "../hooks/isMobile";
import NextIcon from "./svg/NextIcon";
import PrevIcon from "./svg/PrevIcon";
import { getImageURL } from "../helpers/all";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Mousewheel, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/mousewheel";

import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";

const MainSlider = ({ data }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const isMobileLG = useIsMobile("1109px");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const handleNext = () => {
    const nextSlide = activeSlide + 1;
    if (nextSlide > 2) {
      setActiveSlide(2);
    } else {
      setActiveSlide(nextSlide);
    }
  };
  const handlePrev = () => {
    const nextSlide = activeSlide - 1;
    if (nextSlide < 0) {
      setActiveSlide(0);
    } else {
      setActiveSlide(nextSlide);
    }
  };

  return (
    <section className="main-slider mb-6">
      <Swiper
        loop={false}
        effect={"fade"}
        spaceBetween={20}
        thumbs={{
          swiper:
            thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[EffectFade, Thumbs, Navigation, Autoplay]}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        className="mainslides"
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          768: {
            pagination: false,
          },
        }}
      >
        {data.map((e, index) => (
          <SwiperSlide key={index}>
            <GameLarge
              isActive={activeSlide === index}
              data={e}
            />
            {/* <GameLarge
              isActive={activeSlide === index}
              title={e.title ? e.title : "Название"}
              imgFull={getImageURL({
                path: e.media,
                type: "sale",
                size: "full",
              })}
              id={e.categoryId}
              btn={e.btn}
              imgMini={getImageURL({ path: e.mediaMini, type: "sale" })}
            /> */}
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev btn-2">
          <HiArrowNarrowLeft />
        </div>
        <div className="swiper-button-next btn-2">
          <HiArrowNarrowRight />
        </div>
      </Swiper>

      <Swiper
        direction="vertical"
        loop={false}
        spaceBetween={20}
        slidesPerView={5}
        watchSlidesProgress={true}
        modules={[Thumbs, Autoplay, Mousewheel]}
        mousewheel={true}
        className="thumbslides"
        onSwiper={setThumbsSwiper}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
      >
        {data.map((e, index) => (
          <SwiperSlide key={index}>
            <GameMini
              isActive={activeSlide === index}
              title={e.title ? e.title : "Название"}
              imgFull={getImageURL({
                path: e.media,
                type: "sale",
                size: "full",
              })}
              id={e.categoryId}
              btn={e.btn}
              imgMini={getImageURL({ path: e.mediaMini, type: "sale" })}
              onClick={() => setActiveSlide(index)}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <div className={"main-slider-box pos-" + (activeSlide + 1)}>
        {data.map((e, index) => (
          <MainSlide
            isActive={activeSlide === index}
            title={e.title ? e.title : "Название"}
            imgFull={getImageURL({
              path: e.media,
              type: "sale",
              size: "full",
            })}
            btn={e.btn}
            imgMini={getImageURL({ path: e.mediaMini, type: "sale" })}
            onClick={() => setActiveSlide(index)}
          />
        ))}
      </div>
      {isMobileLG && (
        <>
          <button
            type="button"
            className="main-slider-prev"
            onClick={handlePrev}
          >
            <PrevIcon />
          </button>
          <button
            type="button"
            className="main-slider-next"
            onClick={handleNext}
          >
            <NextIcon />
          </button>
        </>
      )}
      {isMobileLG && (
        <ul className="main-slider-indicators">
          {data.map((e, index) => (
            <li className={activeSlide === index ? "active" : ""}></li>
          ))}
        </ul>
      )} */}
    </section>
  );
};

export default MainSlider;
