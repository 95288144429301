import React, { useState } from 'react';
import StarRating from './utils/StarRating';
import InputRating from './utils/InputRating';
import Textarea from './utils/Textarea';
import moment from "moment";
import { customPrice, getImageURL } from '../helpers/all';
import { LuPencil, LuTrash2, LuX } from "react-icons/lu";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

const FeedbackLine = ({ text, value, createdAt, author, product, my, user, order }) => {
  const [showModalFeedback, setShowModalFeedback] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const data = moment(createdAt).fromNow();
  const [clampOff, setClampOff] = useState(false);

  const toggleClamp = () => {
    setClampOff(!clampOff);
  };
  const image = getImageURL({ path: author?.media, type: "user" })
  return (
    <>
      <div className="user-review">
        {my ?
          <>
            <div className="img">
              <Link to={`/profile/${user.id}`}>
                <img src={getImageURL({ path: user?.media, type: "user" })} alt="author" />
              </Link>
            </div>
            <div className="grid-1">
              <Link to={`/profile/${user.id}`}>
                <h4 className="color-1">
                  {user.nickname}
                </h4>
              </Link>
            </div>
          </>
          :
          <>
            <div className="img">
              <Link to={`/profile/${author.id}`}>
                <img src={image} alt="author" />
              </Link>
            </div>
            <div className="grid-1">
              <Link to={`/profile/${author.id}`}>
                <h4 className="color-1">
                  {author.nickname}
                </h4>
              </Link>
            </div>
          </>
        }
        <div className="grid-2">
          <Link to={`/lot/${product.uid ?? product.id}`}>
            <h5>{product?.category?.title}</h5>
          </Link>
        </div>
        <div className="grid-3">
          <StarRating value={value} />

        </div>
        <div className="grid-4">
          {order?.total && <p>{customPrice(Math.round(parseFloat(order?.total) * 10000) / 10000)}</p>}
        </div>
        <div className="grid-6">

          <time>{data}</time>
        </div>
        <div className="grid-5" >
          <p className={clampOff ? 'clamp-off' : ''} onClick={toggleClamp}>{text}</p>
        </div>

      </div>

    </>
  );
};

export default FeedbackLine;