import React from 'react';
import { Row, Table } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

const Rules = () => {
  return (
    <main>
      <Container>
        <div className='rules'>
          <div className='top'>
            <h1 >Правила Сайта</h1>
          </div>
          <div className='content'>
            <p>Настоящий документ (далее — Правила Сайта, Правила, Правила Сервиса), является частью публичной оферты, размещенной по адресу https://speedytrade.ru/rules. Раскрытие всех терминов и определений, общих для Правил и Соглашения, приведено в Соглашении.</p>
            <Table responsive striped borderless>
              <thead>
                <tr>
                  <th>Нарушение</th>
                  <th>Санкции</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    1. Запрещается размещать на сайте заведомо ложную информацию о товарах или услугах, их наличии, качестве, количестве, характеристиках.
                  </td>
                  <td>
                    Предупреждение, блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    2. Запрещается вводить в заблуждение, обманывать пользователей и Администрацию Сайта.
                  </td>
                  <td>
                    Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                  </td>
                </tr>

                <tr>
                  <td>
                    3. Запрещается «накручивать» (необоснованно изменять численные показатели в сторону увеличения) количество совершенных сделок и отзывов. Безосновательно изменять отзывы по старым заказам.
                  </td>
                  <td>
                    Удаление отзыва. Удаление всех отзывов. Блокировка аккаунта при повторном нарушении.
                  </td>
                </tr>
                <tr>
                  <td>
                    4. Запрещается отправлять пользователям личные данные, контакты и ссылки на сторонние сайты и сервисы любыми способами.
                  </td>
                  <td>
                    Предупреждение. Блокировка аккаунта и отказ в выплатах при повторном нарушении.
                  </td>
                </tr>
                <tr>
                  <td>
                    5. Передача третьим лицам какой-либо информации о пользователях или сделках (совершенных или несовершенных) с целью нанесения вреда пользователям.
                  </td>
                  <td>
                    Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                  </td>
                </tr>
                <tr>
                  <td>
                    6. Размещение в любом виде порнографической, экстремистской, а также любой другой противозаконной и политической информации.
                  </td>
                  <td>
                    Удаление информации и сообщений. При повторном нарушении блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    7. Запрещается осуществлять обмен денежных средств из одной платёжной системы в другую с целью совершения финансовых махинаций.
                  </td>
                  <td>
                    Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                  </td>
                </tr>
                <tr>
                  <td>
                    8. Запрещается размещать объявления на других площадках.
                  </td>
                  <td>
                    Предупреждение, блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    9. Запрещается продажа товаров, полученных посредством обмана других пользователей или полученных любым другим незаконным путем.
                  </td>
                  <td>
                    Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                  </td>
                </tr>
                <tr>
                  <td>
                    10. Запрещена продажа и распространение персональных данных, телефонных номеров, нелицензионного и/или вредоносного ПО, материалов, содержащих порнографию, эротику, информацию, связанную со ставками и казино, «рандомов» и любую другую незаконную информацию, а также продажа и распространение товара или оказание услуг в нарушение действующего законодательства.
                  </td>
                  <td>
                    Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                  </td>
                </tr>
                <tr>
                  <td>
                    11. Запрещается продажа и распространение обучений о неправомерной деятельности.
                  </td>
                  <td>
                    Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                  </td>
                </tr>
                <tr>
                  <td>
                    12. Запрещена продажа товаров, осуществляемая с нарушением исключительных прав третьих лиц и лицензионных соглашений с их участием, без разрешения правообладателя.
                  </td>
                  <td>
                    Блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    13. Запрещается неуважительное общение и угрозы в сторону Администрации сайта
                  </td>
                  <td>
                    Предупреждение, блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    14. Запрещается отправка спама в Службу поддержки сайта
                  </td>
                  <td>
                    Предупреждение, блокировка аккаунта и отказ в выплатах.
                  </td>
                </tr>
                <tr>
                  <td>
                    15. Запрещается спам, флуд, ненормативная лексика, оскорбления, угрозы, политические обсуждения. В общем чате запрещается отправка ссылок на лоты, реклама торговых интернет-ресурсов, а также любые сообщения, нарушающие действующее законодательство.
                  </td>
                  <td>
                    Предупреждение и блокировка сообщений. При многократном нарушении блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    16. Запрещается осуществлять сделки с целью написания негативных отзывов, которые не соответствуют действительности (борьба с конкурентами и тп).
                  </td>
                  <td>
                    Предупреждение, блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    17. Запрещается дублирование объявлений. Размещение объявлений о покупке.
                  </td>
                  <td>
                    Удаление объявлений, предупреждение, блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    18. Запрещается предоставление финансов и/или товара/услуги участнику сделки до момента изъявления согласия сторон и подтверждения намерения заключить сделку обеими сторонами, на сторонних ресурсах и/или в авансовом порядке. В этом случае администрация проекта не сможет вернуть средства и аннулировать сделку.
                  </td>
                  <td>
                    Отказ в поддержке и невозможность последующего обращения по сделке в поддержку. Блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    19. Запрещается размещать объявления в непредназначенных для этого разделах.
                  </td>
                  <td>
                    Предупреждение, блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    20. Запрещен призыв к совершению сделок или общению за пределами SpeedyTrade в мессенджерах, социальных сетях, форумах, других площадках.
                  </td>
                  <td>
                    Предупреждение, блокировка аккаунта и отказ в выплатах при повторном нарушении.
                  </td>
                </tr>
                <tr>
                  <td>
                    21. Запрещается размещать объявления с не существующими товарами или услугами с целью демпинга и попытки искусственного изменения рыночных цен.
                  </td>
                  <td>
                    Предупреждение, удаление объявлений, блокировка аккаунта.
                  </td>
                </tr>
                <tr>
                  <td>
                    22. Запрещается подтверждение сделки до получения товара или услуги в полном объеме.
                  </td>
                  <td>
                    Отказ в поддержке и невозможность последующего обращения по сделке в поддержку. В этом случае администрация проекта не сможет вернуть средства и аннулировать сделку.
                  </td>
                </tr>
                <tr>
                  <td>
                    23. Запрещается требовать подтверждение сделки у второй стороны до предоставления услуги в полном объеме
                  </td>
                  <td>
                    Предупреждение, блокировка;
                  </td>
                </tr>
              </tbody>
            </Table>
            <p>В случае блокировки аккаунта для проведения внутреннего расследования все денежные операции, ожидающие вывода, отменяются, и средства возвращаются на внутренний баланс Сервиса в рамках аккаунта «замороженного» пользователя. Возврат денежных средств осуществляется при наличии таковых на счете нарушителя, в иных случаях возврат не может быть осуществлен. </p>
            <p>В случае выявления нарушений, подпадающих под запрет Правил сайта и законодательства, Администрация оставляет за собой право на обращение в правоохранительные органы с заявлениями о привлечении нарушителей и злоумышленников к предусмотренной законом ответственности, с предоставлением всей персональной информации лица, подозреваемого в мошенничестве или иной противоправной деятельности. </p>
            <p>При нарушении Правил Сайта и Сервисов Администрация вправе изменять персональные условия пользования площадкой Сайта для нарушителя. </p>
            <p>Если Вы хотите обменяться контактными данными с другим продавцом для совместного выполнения заказов, подайте заявку в Службу поддержки Сайта. При этом Администрация оставляет за собой право на связь с продавцами под видом покупателей с целью выявления нарушений. </p>
            <p>Администрация оставляет за собой право проводить проверки в отношении размещаемых на площадке товаров и деятельности пользователей, их размещающих. </p>
            <p>Администрация оставляет за собой право смягчить или отменить любое наложенное наказание, если оно нецелесообразно в каком-либо конкретном случае. </p>
            <p>Каждый спор между покупателем и продавцом индивидуален, поэтому окончательное решение о степени ответственности продавца может существенно отличаться от написанного выше. Договорённости в чате между продавцом и покупателем могут быть приняты во внимание при решении спорных вопросов.</p>

            {/* 
                        <button type="button" className="btn-5 fs-11 mt-4 mt-sm-5">
                            Принять условия
                        </button> */}
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Rules;